import type { IInputDataFormDataSolar } from "../../../interfaces/solar/IRespOutputDataSolar";

export const generateEditableData = (newFormData: IInputDataFormDataSolar) => ({
	sfv_information: {
		installation_type: newFormData?.sfv_information.installation_type ?? "",
		inverter_type: newFormData?.sfv_information.inverter_type ?? "",
		epecista: newFormData?.sfv_information.epecista ?? "",
		epecista_new: newFormData?.sfv_information.epecista_new ?? "",
		kwp_capacity_sfv: newFormData?.sfv_information?.kwp_capacity_sfv ?? 0,
		dongle_exists: false,
		dtu_exists: newFormData?.sfv_information?.dtu_exists,
	},
	access_installation: {
		smartbit_meter_integration:
			newFormData?.access_installation.smartbit_meter_integration ?? "",
		de_energization_energy_network:
			newFormData?.access_installation.de_energization_energy_network ?? "",
		de_energization_energy_inverter:
			newFormData?.access_installation.de_energization_energy_inverter ?? "",
	},
	features_solar_meter: {
		measurement_type: newFormData?.features_solar_meter.measurement_type ?? "",
		meter_high: newFormData?.features_solar_meter.meter_high || 0,
		meter_location: newFormData?.features_solar_meter.meter_location ?? "",
		meter_size: newFormData?.features_solar_meter.meter_size ?? "",
		number_connection_elements:
			newFormData?.features_solar_meter.number_connection_elements ?? "",
		number_meter_element:
			newFormData?.features_solar_meter.number_meter_element ?? "",
		number_of_phases: newFormData?.features_solar_meter.number_of_phases ?? "",
	},
});
