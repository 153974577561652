import {
	Box,
	Checkbox,
	type GridColDef,
	type GridRenderCellParams,
	type GridRowParams,
	Radio,
	Typography,
	esES,
} from "@enerbit/base";
import type { GridRowSpacingParams } from "@mui/x-data-grid";
import { type FC, useCallback, useEffect, useState } from "react";
import { AddressPagination } from ".";
import { StyledDataGrid } from "../../common";
import type { ILeadItem } from "../../interfaces";
import { useTableStore } from "../../store";

interface Props {
	multiple?: boolean;
}

export const AddressTableLead: FC<Props> = ({ multiple }) => {
	const [isFirstRender, setIsFirstRender] = useState(true);

	const {
		search,
		listLeads,
		loading,
		size,
		rowCount,
		selectedLeads,
		page,
		name,
		setPage,
		setPageSize,
		handleLead,
		handleLeads,
		getLeads,
	} = useTableStore();

	const columns: GridColDef[] = [
		{
			field: "display_name",
			headerName: "Nombre",
			flex: 1,
			align: "left",
			headerAlign: "center",
			renderCell: (params: GridRenderCellParams<string, ILeadItem>) => {
				const value = params.value;
				const { id: IDRow, city, address } = params.row;
				const isChecked = !!selectedLeads.find(({ id }) => id === IDRow);
				return (
					<Box sx={{ display: "flex", gap: 2 }}>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							{multiple ? (
								<Checkbox
									checked={isChecked}
									onChange={() => handleLeads(params.row)}
									color="secondary"
									size="small"
								/>
							) : (
								<Radio
									checked={isChecked}
									onChange={() => handleLead(params.row)}
									color="secondary"
									size="small"
								/>
							)}
						</Box>
						<Box>
							<Typography sx={{ fontWeight: "bold" }}>{value}</Typography>
							<Typography>
								{city || "No registra"}, {address || "No registra"}
							</Typography>
						</Box>
					</Box>
				);
			},
			sortable: false,
		},
	];

	const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
		return {
			top: params.isFirstVisible ? 0 : 5,
			bottom: params.isLastVisible ? 0 : 5,
		};
	}, []);

	const getRowClassName = (params: GridRowParams): string => {
		return selectedLeads.some((lead) => lead.id === params.id)
			? "selected-row"
			: "";
	};

	useEffect(() => {
		if (!isFirstRender) {
			if (search) {
				getLeads(search, page, size, name);
			}
		} else {
			setPage(1);
			setIsFirstRender(false);
		}
	}, [page, size, isFirstRender, getLeads, search, name, setPage]);

	return (
		<Box sx={{ width: "100%" }}>
			<StyledDataGrid
				sx={{
					"& .MuiDataGrid-virtualScrollerContent": {
						height: listLeads.length > 0 ? "auto!important" : "100px!important",
					},
					"& .MuiDataGrid-row": {
						minHeight: "52px!important",
						py: 1,
					},
				}}
				getRowHeight={() => "auto"}
				disableVirtualization
				paginationMode="server"
				disableColumnMenu
				rowSpacingType="border"
				getRowSpacing={getRowSpacing}
				getRowClassName={getRowClassName}
				rows={listLeads}
				selectionModel={selectedLeads.map(({ id }) => id)}
				getRowId={(row) => row.id}
				onSelectionModelChange={(e) => {
					if (e.length > 0) {
						const selectedIDs = new Set(e);

						const selectedRowData = listLeads.filter((row) =>
							selectedIDs.has(row.id),
						)[0];
						if (multiple) {
							handleLeads(selectedRowData);
						} else {
							handleLead(selectedRowData);
						}
					}
				}}
				headerHeight={0}
				rowHeight={100}
				columns={columns}
				pageSize={size}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowCount={rowCount}
				onPageChange={(page) => setPage(page)}
				onPageSizeChange={(pageSize) => setPageSize(pageSize)}
				loading={loading}
				autoHeight
				localeText={esES.components.MuiDataGrid.defaultProps.localeText}
				pagination
				components={{
					Pagination: AddressPagination,
				}}
			/>
		</Box>
	);
};
