import type {
	IIncomingDataItem,
	IPhaseVoltageTransformer,
} from "../../interfaces";

export const generateVoltageTransformerID = (
	phase?: IPhaseVoltageTransformer | null,
): IIncomingDataItem[] => {
	if (!phase) return [];

	return [
		{
			field: "Burden (VA)",
			value: phase?.burden ?? "",
			description: "Cargabilidad según placa de características.",
		},
		{
			field: "Tipo exterior o interior",
			value: phase.is_exterior ? "Exterior" : "Interior",
			description: "Identificar si es tipo interior o exterior",
		},
		{
			field: "Clase de exactitud (%)",
			value: phase?.percentage_exactitude_class ?? "",
			description: "Clase de exactitud en placa",
		},
		{
			field: "Tensión Secundaria nominal (A) fase R",
			value: phase?.secundary_nominal_voltage ?? "",
			description: "Tension secundaria en amperios",
		},
		{
			field: "Relación de transformación en servicio",
			value: phase?.transformation_relationship ?? "",
			description: "Notación XXX/X ó XXX-XXX/X para 2 devanados",
		},
		{
			field: "Cargas para compensación de burden (VA)",
			value: phase?.charges_for_burden_compensation ?? "",
			description: "Cargas de Compensación en VA",
		},
		{
			field: "Serie del transformador",
			value: phase?.serial_number.value ?? "",
			description: "Serie del transformador",
		},
		{
			field: "Fabricante transformador",
			value: phase?.manufacturer ?? "",
			description: "Fabricante transformador",
		},
		{
			field: "Año de fabricación transformador",
			value: phase?.manufacture_year ?? "",
			description: "Año de fabricación transformador",
		},
	];
};
