import {
	Button,
	CircularProgress,
	DialogContent,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	type SelectChangeEvent,
	TextField,
	useSnackbar,
} from "@enerbit/base";
import { type ChangeEvent, type FC, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { AddressTableCustomer, AddressTableLead } from ".";
import { StyledDialog, StyledDialogTitle } from "../../common";
import { EEntity } from "../../interfaces";
import { useTableStore } from "../../store";

interface Props {
	existsEntity?: EEntity;
}

export const AddressModal: FC<Props> = ({ existsEntity }) => {
	const {
		selectedLeads,
		selectedCustomers,
		open,
		loading,
		name,
		entity,
		search,
		page,
		size,
		setName,
		closeModal,
		getLeads,
		getCustomers,
		setEntity,
		handleAddEntity,
		handleAddEntities,
		resetPartialTable,
	} = useTableStore();

	const handleClose = () => {
		closeModal();
	};

	const handleAction = () => {
		if (entity === EEntity.Customer) {
			handleAddEntity(
				selectedCustomers.map((customer) => {
					const { measurement_point_id, pii, location } = customer;
					return {
						id: measurement_point_id,
						full_name: `${pii.names} ${pii.last_names}`,
						address: location.address,
						department: location.state,
						city: location.city,
					};
				})[0],
			);
		} else {
			if (entity === EEntity.Lead) {
				handleAddEntity(
					selectedLeads.map((leadItem) => ({
						id: leadItem.id,
						secondary_id: leadItem.measurement_point_id, // O cualquier otro campo apropiado
						full_name: `${leadItem.name} ${leadItem.last_name}`,
						address: leadItem.address,
						city: leadItem.city,
						department: leadItem.department,
						email: leadItem.email_from, // Asumiendo que email_from es el correo electrónico que quieres usar
					}))[0],
				);
			} else {
				handleAddEntities(
					selectedLeads.map((leadItem) => ({
						id: leadItem.id,
						secondary_id: leadItem.measurement_point_id, // O cualquier otro campo apropiado
						full_name: `${leadItem.name} ${leadItem.last_name}`,
						address: leadItem.address,
						city: leadItem.city,
						department: leadItem.department,
						email: leadItem.email_from, // Asumiendo que email_from es el correo electrónico que quieres usar
					})),
				);
			}
		}
		resetPartialTable();
		handleClose();
	};

	const debouncedSearch = useDebouncedCallback((value) => {
		if (entity === EEntity.Customer) {
			getCustomers(search, page, size, value);
		} else if (entity === EEntity.Lead) {
			getLeads(search, page, size, value);
		}
	}, 500);

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const nextValue = e.target.value.trim();
		setName(nextValue);
		debouncedSearch(nextValue);
	};

	const handleChangeEntity = (event: SelectChangeEvent<EEntity>) => {
		const newEntity = event.target.value as EEntity;
		setEntity(newEntity);
	};

	useEffect(() => {
		if (existsEntity) {
			setEntity(existsEntity);
		}
	}, [existsEntity, setEntity]);

	return (
		<StyledDialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			maxWidth="lg"
			fullWidth
		>
			<StyledDialogTitle id="customized-dialog-title" onClose={handleClose}>
				Predios
			</StyledDialogTitle>
			<DialogContent>
				<Grid container spacing={3}>
					{!existsEntity && (
						<Grid item xs={12}>
							<InputLabel className="title-input-edition" required>
								Lead o cliente
							</InputLabel>
							<FormControl fullWidth>
								<Select
									id="entity-selection"
									value={entity ?? ""}
									onChange={handleChangeEntity}
									sx={{ "& fieldset": { borderRadius: "14px" } }}
									disabled={loading}
									variant="outlined"
									fullWidth
								>
									<MenuItem value={EEntity.Lead}>Lead</MenuItem>
									<MenuItem value={EEntity.Customer}>Cliente</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					)}
					<Grid item xs={12}>
						<InputLabel className="title-input-edition">
							Buscar por nombre
						</InputLabel>
						<FormControl fullWidth>
							<TextField
								value={name}
								onChange={handleSearchChange}
								sx={{ "& fieldset": { borderRadius: "14px" } }}
								variant="outlined"
								placeholder="Buscar"
								fullWidth
								InputProps={{
									endAdornment:
										name && loading ? (
											<InputAdornment position="end">
												<CircularProgress size={20} thickness={6} />
											</InputAdornment>
										) : null,
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						{(entity === EEntity.Lead || entity === EEntity.Cabinet) && (
							<AddressTableLead />
						)}
						{entity === EEntity.Customer && <AddressTableCustomer />}
					</Grid>
					<Grid item xs={12}>
						<Button
							id="action-button"
							fullWidth
							disabled={
								!(selectedLeads.length > 0 || selectedCustomers.length > 0)
							}
							variant="contained"
							color="secondary"
							onClick={handleAction}
						>
							Seleccionar
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</StyledDialog>
	);
};
