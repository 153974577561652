import { api } from "@enerbit/base";
import { convertMoneyToString } from ".";
import type { IMaterial, IMaterialsItem, IRespMaterials } from "../interfaces";

export const getAllMaterials = async (materials: IMaterial[]) => {
	try {
		const response = await api.get<IRespMaterials>("inventory/inventories/");
		const allItems: IMaterialsItem[] = response.data.items;

		for (const material of materials) {
			const matchedItem = allItems.find(
				(item) =>
					item["Texto breve material"].toLowerCase() ===
					material.name.toLowerCase(),
			);
			if (matchedItem?.["Valor unitario con IVA"]) {
				material.charge = convertMoneyToString(
					matchedItem["Valor unitario con IVA"],
				);
			} else {
				material.charge = convertMoneyToString(material.charge || "0");
			}
		}

		return materials.map((material) => ({
			...material,
			charge: convertMoneyToString(material.charge),
		}));
	} catch (error) {
		console.error("An error occurred:", error);
		return materials.map((material) => ({
			...material,
			charge: convertMoneyToString(material.charge || "0"),
		}));
	}
};
