import { api } from "@enerbit/base";
import { AxiosError } from "axios";
import { create } from "zustand";
import { installationCosts, listCategories } from "../const";
import {
	checkTransformerPhases,
	convertMoneyToString,
	generateIncomingData,
	generateMeasurementSystem,
	getVoltageLevel,
	selectByMeasurement,
} from "../helpers";
import { generateConcept } from "../helpers/concept";
import { generateDocumentaryCompliance } from "../helpers/documentary-compliance";
import {
	transformActivities,
	transformMaterials,
} from "../helpers/material-labor";
import { getAllPicturesByInspectionForm } from "../helpers/photographs";

import type {
	IGetOrders,
	IInstallationItem,
	IMaterialExtended,
	IRespMaterials,
} from "../interfaces";
import type { IEmissionStore } from "../interfaces/IEmissionStore";
import type { IRespOutputData } from "../interfaces/IRespOutputData";
import { convertHeicToJpeg } from "../utils";

const initialState = {
	installation: null,
	outputData: null,
	loadingEmission: false,
	incomingData: null,
	measurementSystem: null,
	documentaryCompliance: null,
	photographs: null,
	materials: [],
	listCategories: listCategories(),
	listMaterials: [],
	listQuantities: Array.from({ length: 10 - 1 + 1 }, (_, index) => 1 + index),
	isLoadingMaterials: false,
	concept: [],
	previous_diagnostic: null,
	field: null,
	errorMessage: "",
	installationCosts: installationCosts(),
	existsOrderInstallation: false,
};

export const useEmissionStore = create<IEmissionStore>()((set) => ({
	...initialState,
	getOutputData: async (lead) => {
		try {
			const { id } = lead;
			set({
				...initialState,
				loadingEmission: true,
				errorMessage: "",
			});
			const { data } = await api.get<IRespOutputData>(
				`/frontier-initial-diagnostic/output-data?diagnostic_type=conventional&lead_id=${id}&is_field=true`,
			);

			const { data: dataOrders } = await api.get<IGetOrders>(
				`/service-orders/orders?sort_planned_date_begin=DESC&assignee_id=${id}&entity_code=LD&status=done&page=0&size=5`,
			);

			const validOrderCodes = [
				"CCE",
				"CCEG",
				"CS",
				"CSG",
				"NS",
				"NSG",
				"NSDI",
				"NSDG",
			];

			const { field, previous_diagnostic } = data;

			const documentaryCompliance = field?.input_data.form_data
				? await generateDocumentaryCompliance(field?.input_data.form_data)
				: null;
			const incomingData = field ? generateIncomingData(lead, field) : null;
			const measurementSystem = field ? generateMeasurementSystem(field) : null;
			const photographs = field?.input_data.form_data
				? await convertHeicToJpeg(
						getAllPicturesByInspectionForm(field?.input_data.form_data),
					)
				: null;

			let materials: IMaterialExtended[];
			if (previous_diagnostic) {
				materials = await transformMaterials(
					previous_diagnostic?.form_data.materials_and_labor.materials,
				);
			} else {
				const tempMaterial = await transformMaterials(
					field?.input_data.form_data.materials_to_execute,
				);
				tempMaterial.concat(
					transformActivities(
						field?.input_data.form_data.activities_to_execute,
					),
				);
				materials = tempMaterial;
			}

			const concept = generateConcept(field?.input_data.form_data.observations);
			set({
				outputData: data,
				incomingData,
				measurementSystem,
				documentaryCompliance,
				photographs,
				materials,
				concept,
				previous_diagnostic,
				field,
				existsOrderInstallation: !!dataOrders.items.find((order) =>
					validOrderCodes.includes(order.order_type.code),
				),
				loadingEmission: false,
			});
		} catch (error) {
			set({
				loadingEmission: false,
			});
			if (error instanceof AxiosError) {
				if (error.response?.status === 404) {
					set({
						errorMessage: "Este usuario no cuenta con inspección previa.",
					});
				} else {
					set({
						errorMessage: "Ha ocurrido un error desconocido.",
					});
				}
			} else if (error instanceof Error) {
				set({
					errorMessage: `Error inesperado: ${error.message}`,
				});
			} else {
				set({
					errorMessage: "Error desconocido.",
				});
			}
		}
	},
	deleteMaterial: (id) => {
		set((state) => ({
			materials: state.materials.filter((material) => material.id !== id),
		}));
	},
	updateQuantityMaterial: (id, quantity) => {
		set((state) => ({
			materials: state.materials.map((material) =>
				material.id === id
					? {
							...material,
							quantity: quantity,
							subtotal: quantity * Number.parseFloat(material.charge),
						}
					: material,
			),
		}));
	},
	getMaterials: async (category) => {
		try {
			set({ isLoadingMaterials: true, errorMessage: "" });
			const { data } = await api.get<IRespMaterials>(
				`inventory/inventories/?category=${category}`,
			);
			set({
				isLoadingMaterials: false,
				listMaterials: data.items
					.filter(
						(item) =>
							item["Valor unitario con IVA"] &&
							convertMoneyToString(item["Valor unitario con IVA"]) !== "0",
					)
					.sort((a, b) =>
						a["Texto breve material"].localeCompare(b["Texto breve material"]),
					),
			});
		} catch (error) {
			set({
				isLoadingMaterials: false,
				errorMessage: "No se pudo encontrar los materiales.",
			});
		}
	},
	addMaterial: (material) => {
		set((state) => {
			const newMaterials = [...state.materials, material];
			return {
				materials: newMaterials,
			};
		});
	},
	calculateViability: (lead) => {
		set((state) => {
			const measurementType =
				state.outputData?.field?.input_data.form_data.main_meter?.measurement_type?.toLowerCase() ??
				"";
			const voltageLevel = getVoltageLevel(
				state.outputData?.field?.input_data.form_data.general_data
					?.voltage_level ?? 0,
			);
			const selectedMeasurements =
				selectByMeasurement(
					lead.type_services,
					measurementType,
					state.installationCosts,
				) ?? [];

			let installation: IInstallationItem | undefined;
			if (["indirecta", "semidirecta"].includes(measurementType)) {
				const phases = checkTransformerPhases(
					state.outputData?.field?.input_data.form_data.current_transformer,
					measurementType === "indirecta"
						? state.outputData?.field?.input_data.form_data.voltage_transformer
						: null,
				);
				installation = selectedMeasurements.find(
					({ type }) =>
						type.includes(voltageLevel) &&
						((type.includes("dos") && phases === 2) ||
							(type.includes("tres") && phases === 3)),
				);
			} else {
				if (selectedMeasurements.length === 1) {
					installation = selectedMeasurements[0];
				} else if (selectedMeasurements.length > 1) {
					const connectionType =
						state.outputData?.field?.input_data.form_data.general_data?.connection?.toLowerCase() ??
						"";
					installation = selectedMeasurements.find(({ type }) =>
						type.toLowerCase().includes(connectionType),
					);
				}
			}

			return { installation };
		});
	},
	createPreviousDiagnostic: (diagnostic) =>
		set({ previous_diagnostic: diagnostic }),
	updatePreviousDiagnostic: (diagnostic) =>
		set((state) => ({
			previous_diagnostic: {
				...state.previous_diagnostic,
				form_data: diagnostic,
			} as Partial<IEmissionStore>["previous_diagnostic"],
		})),
	clearError: () => set({ errorMessage: "" }),
}));
