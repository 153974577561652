import type { IIncomingDataItem } from "../../interfaces";
import type { IObservationsSolar } from "../../interfaces/solar/IRespOutputDataSolar";

export const generateConceptSolar = (
	observations?: IObservationsSolar,
): IIncomingDataItem[] => {
	if (!observations) return [];

	return [
		{
			field: "Mecanismo de suspensión",
			value: observations.for_us.suspension_mechanism ?? "No",
			description: "",
		},
		{
			field: "¿Este sistema de medida corresponde a una zona común?",
			value: observations.is_common_measurement_system ? "Si." : "No.",
			description: "",
		},
		{
			field:
				"¿Requiere apertura para la siguiente visita de instalación o normalización?",
			value: observations.is_special_equipment_need ? "Si." : "No.",
			description: "",
		},
		...(observations.next_visit_open
			? [
					{
						field: "¿Cuánto tiempo el cliente permanecería sin energía?",
						value: observations.next_visit_open,
						description: "",
					},
				]
			: []),
		{
			field:
				"¿Se requiere equipo de trabajo o herramienta especial para la siguiente visita de instalación o normalización?",
			value: observations.is_special_equipment_need ? "Si." : "No.",
			description: "",
		},
		{
			field: "Herramienta especial",
			key: "special_tools",
			value: observations.special_tools ?? "",
			description: "",
		},
		// {
		//   field: "¿Se requiere acompañamiento del epecista?",
		//   value: observations.is_epecista_support ?? "",
		//   description: "",
		//   key: "is_epecista_support",
		// },
		// {
		//   field: "Requerimiento",
		//   value: observations.epecista_requirement ?? "",
		//   description: "",
		//   key: "epecista_requirement",
		// },
	];
};
