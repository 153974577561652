export const listCategories = () => {
	return [
		"Bornera",
		"Conductor",
		"Caja",
		"Canalización",
		"Sellos y bolsas",
		"Medidor",
		"Comunicación",
		"Sim Card",
		"Transformador corriente",
		"Transformador de tensión",
		"Cortacircuitos",
		"Otros materiales",
		"Materiales de consumo",
		"Equipos",
		"Herramienta",
		"EPP",
	];
};
