import { Grid, Typography, enerbitColors } from "@enerbit/base";
import { type FC, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/counter.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import type { IPictureArraySolar } from "../../interfaces";
import { PhotoCardSolar } from "./PhotoCardSolar";

interface Props {
	name: string;
	data?: IPictureArraySolar[] | null;
}

export const PhotographsSectionSolar: FC<Props> = ({ name, data }) => {
	const [index, setIndex] = useState(-1);
	const [section, setSection] = useState("");
	const newImages = data?.map((image) => ({
		section: image.image_title,
		...image,
	}));

	const onClick = (index: number, section: string) => {
		setIndex(index);
		setSection(section);
	};

	return (
		<>
			{newImages && newImages?.length > 0 && (
				<Grid item container xs={12} spacing={3}>
					{newImages.map((item, index) => {
						return (
							<>
								<Grid key={index.toString()} item xs={12}>
									<Typography
										fontWeight={"bold"}
										color={enerbitColors.primary.main}
									>
										{item.image_title}
									</Typography>
								</Grid>
								{item.image_url?.map((item2, index2) => {
									return (
										<PhotoCardSolar
											key={index2.toString()}
											image={item2}
											index={index2}
											onClick={() => onClick(index2, item.image_title)}
										/>
									);
								})}
							</>
						);
					})}
					<Lightbox
						plugins={[Captions, Counter, Zoom]}
						index={index}
						slides={
							newImages
								?.find((item) => item.section === section)
								?.image_url?.map((imageUrl) => ({
									src: imageUrl.image_url ?? "",
									description: imageUrl.image_title ?? "",
								})) || []
						}
						open={index >= 0}
						close={() => setIndex(-1)}
					/>
				</Grid>
			)}
		</>
	);
};
