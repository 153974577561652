import type { IInstallationCost } from "../interfaces";

export const installationCosts = (): IInstallationCost[] => {
	return [
		{
			category: "Directa residencial",
			installations: [
				{
					type: "Monofásica",
					device: 425700,
					measurement: 463578,
					labor: 233271,
					previousVisit: 130900,
					totalInstallation: 1253449,
				},
				{
					type: "Trifásica",
					device: 468200,
					measurement: 676918,
					labor: 233271,
					previousVisit: 130900,
					totalInstallation: 1509289,
				},
				{
					type: "Centralizada (mono o tri)",
					device: 237118,
					measurement: 533319,
					labor: 116635,
					previousVisit: 130900,
					totalInstallation: 1017972,
				},
			],
		},
		{
			category: "Directa",
			installations: [
				{
					type: "Empresarial",
					device: 468200,
					measurement: 676918,
					labor: 233271,
					previousVisit: 196350,
					totalInstallation: 1574739,
				},
			],
		},
		{
			category: "Semidirecta",
			installations: [
				{
					type: "N1 dos elementos",
					device: 425700,
					measurement: 2568606,
					labor: 1036333,
					previousVisit: 196350,
					totalInstallation: 4226989,
				},
				{
					type: "N1 tres elementos",
					device: 425700,
					measurement: 2754825,
					labor: 1036333,
					previousVisit: 196350,
					totalInstallation: 4413208,
				},
			],
		},
		{
			category: "Indirecta",
			installations: [
				{
					type: "N2 dos elementos",
					device: 425700,
					measurement: 10018915,
					labor: 1208596,
					previousVisit: 196350,
					totalInstallation: 11849561,
				},
				{
					type: "N2 tres elementos",
					device: 425700,
					measurement: 13859085,
					labor: 1208596,
					previousVisit: 196350,
					totalInstallation: 15689731,
				},
				{
					type: "N3 dos elementos",
					device: 425700,
					measurement: 12919188,
					labor: 1208596,
					previousVisit: 196350,
					totalInstallation: 14749834,
				},
				{
					type: "N3 tres elementos",
					device: 425700,
					measurement: 18079172,
					labor: 1208596,
					previousVisit: 196350,
					totalInstallation: 19909818,
				},
			],
		},
	];
};
