import { api } from "@enerbit/base";
import { AxiosError } from "axios";
import { create } from "zustand";
import type { IDownloadStore, IRespConcept } from "../interfaces";

const initialState = {
	loading: false,
	errorMessage: "",
	technical: null,
	business: null,
};

export const useDownloadStore = create<IDownloadStore>()((set) => ({
	...initialState,
	getConcepts: async (id, isSolarPicture) => {
		try {
			set({
				loading: true,
				technical: null,
				business: null,
				errorMessage: "",
			});
			const { data } = isSolarPicture
				? await api.get<IRespConcept>(
						`/lead-documentation/solar-technical-business-concept?lead_id=${id}`,
					)
				: await api.get<IRespConcept>(
						`/lead-documentation/technical-business-concept?lead_id=${id}`,
					);
			if (data.business.length === 0 && data.technical.length === 0) {
				throw new Error(
					"No se encontraron empresas con el filtro proporcionado.",
				);
			}
			set({
				loading: false,
				technical: data.technical.slice(-1)[0],
				business: data.business.slice(-1)[0],
			});
		} catch (error) {
			if (error instanceof AxiosError) {
				if (error.response?.status === 500) {
					set({
						loading: false,
						errorMessage: "Ha ocurrido un error, por favor inténtelo de nuevo.",
					});
				} else {
					set({
						loading: false,
						errorMessage: "No se encontraron conceptos asociados a este Lead.",
					});
				}
			} else if (error instanceof Error) {
				set({
					loading: false,
					errorMessage: `Error inesperado: ${error.message}`,
				});
			} else {
				set({
					loading: false,
					errorMessage: "Error desconocido.",
				});
			}
		}
	},
	resetDownload: () => {
		set(() => ({
			...initialState,
		}));
	},
}));
