import {
	Box,
	Checkbox,
	type GridColDef,
	type GridRenderCellParams,
	type GridRowParams,
	Radio,
	Typography,
	esES,
} from "@enerbit/base";
import type { GridRowSpacingParams } from "@mui/x-data-grid";
import { type FC, useCallback, useEffect, useState } from "react";
import { StyledDataGrid } from "../../common";
import type { ICustomerItem, PiiCustomer } from "../../interfaces";
import { useTableStore } from "../../store";
import { AddressPagination } from "./AddressPagination";

interface Props {
	multiple?: boolean;
}

export const AddressTableCustomer: FC<Props> = ({ multiple }) => {
	const [isFirstRender, setIsFirstRender] = useState(true);

	const {
		listCustomers,
		loading,
		size,
		rowCount,
		selectedCustomers,
		page,
		search,
		name,
		getCustomers,
		handleCustomer,
		handleCustomers,
		setPage,
		setPageSize,
	} = useTableStore();

	const columns: GridColDef[] = [
		{
			field: "pii",
			headerName: "Nombre",
			flex: 1,
			align: "left",
			headerAlign: "center",
			renderCell: (
				params: GridRenderCellParams<PiiCustomer, ICustomerItem, unknown>,
			) => {
				const value = params.value; // Asume que pii contiene los nombres y apellidos
				const { measurement_point_id, location } = params.row;
				const isSelected = selectedCustomers.some(
					(customer) => customer.measurement_point_id === measurement_point_id,
				);
				return (
					<Box sx={{ display: "flex", gap: 2 }}>
						<Box sx={{ display: "flex", alignItems: "center" }}>
							{multiple ? (
								<Checkbox
									checked={isSelected}
									onChange={() => handleCustomers(params.row)}
									color="secondary"
									size="small"
								/>
							) : (
								<Radio
									checked={isSelected}
									onChange={() => handleCustomer(params.row)}
									color="secondary"
									size="small"
								/>
							)}
						</Box>
						<Box>
							<Typography sx={{ fontWeight: "bold" }}>
								{`${value?.names} ${value?.last_names}`}
							</Typography>
							<Typography>
								{location?.city || "No registra"},{" "}
								{location?.address || "No registra"}
							</Typography>
						</Box>
					</Box>
				);
			},
		},
	];

	const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
		return {
			top: params.isFirstVisible ? 0 : 5,
			bottom: params.isLastVisible ? 0 : 5,
		};
	}, []);

	const getRowClassName = (params: GridRowParams): string => {
		return selectedCustomers.some(
			(customer) =>
				customer.measurement_point_id === params.row.measurement_point_id,
		)
			? "selected-row"
			: "";
	};

	useEffect(() => {
		if (!isFirstRender) {
			if (search) {
				getCustomers(search, page, size, name);
			}
		} else {
			setPage(0);
			setIsFirstRender(false);
		}
	}, [page, size, isFirstRender, search, getCustomers, name, setPage]);

	return (
		<Box sx={{ height: 400, width: "100%" }}>
			<StyledDataGrid
				sx={{
					"& .MuiDataGrid-virtualScrollerContent": {
						height:
							listCustomers.length > 0 ? "auto!important" : "100px!important",
					},
					"& .MuiDataGrid-row": {
						minHeight: "52px!important",
						py: 1,
					},
				}}
				getRowHeight={() => "auto"}
				disableVirtualization
				paginationMode="server"
				disableColumnMenu
				rowSpacingType="border"
				getRowSpacing={getRowSpacing}
				getRowClassName={getRowClassName}
				rows={listCustomers}
				selectionModel={selectedCustomers.map(
					({ measurement_point_id }) => measurement_point_id,
				)}
				getRowId={(row) => `${row.measurement_point_id}`}
				onSelectionModelChange={(e) => {
					if (e.length > 0) {
						const selectedIDs = new Set(e);
						const selectedRowData = listCustomers.filter((row) =>
							selectedIDs.has(row.measurement_point_id),
						)[0];
						if (multiple) {
							handleCustomers(selectedRowData);
						} else {
							handleCustomer(selectedRowData);
						}
					}
				}}
				columns={columns}
				pageSize={size}
				rowHeight={90}
				headerHeight={0}
				rowsPerPageOptions={[5, 10, 20, 50]}
				rowCount={rowCount}
				onPageChange={(page) => setPage(page)}
				onPageSizeChange={(pageSize) => setPageSize(pageSize)}
				loading={loading}
				autoHeight
				localeText={esES.components.MuiDataGrid.defaultProps.localeText}
				pagination
				components={{
					Pagination: AddressPagination,
				}}
			/>
		</Box>
	);
};
