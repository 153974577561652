import {
	Box,
	Button,
	CheckCircleIcon,
	Grid,
	Typography,
	WarningIcon,
} from "@enerbit/base";
import { navigateToUrl } from "single-spa";
import { useEmissionStore } from "../../store";

const OrderInstallation = () => {
	const { existsOrderInstallation, field } = useEmissionStore();

	const formData = field?.input_data.form_data;
	const general_data = formData?.general_data;
	const isEnergized =
		formData?.main_meter &&
		formData?.electrical_boxes &&
		general_data?.power_capacity_contracted &&
		general_data?.power_transformer_feature_plate_picture &&
		general_data?.power_transformer_location &&
		general_data?.power_transformer_node &&
		general_data?.power_transformer_phase_number &&
		general_data?.power_transformer_primary_voltage &&
		general_data?.power_transformer_secundary_voltage &&
		general_data?.power_transformer_serial_number &&
		general_data?.power_transformer_usage;

	return (
		<Grid item xs={12}>
			<Box
				sx={{
					borderRadius: "12px",
					backgroundColor: "#EEEBF4",
					display: "flex",
					justifyContent: "space-between",
					px: 4,
					py: 2,
				}}
			>
				<Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
					{existsOrderInstallation ? (
						<CheckCircleIcon color={"success"} sx={{ fontSize: "18px" }} />
					) : (
						<WarningIcon color={"warning"} sx={{ fontSize: "18px" }} />
					)}
					<Box>
						{!isEnergized && (
							<Typography color="primary" fontWeight="bold" fontSize="16px">
								Proyecto desenergizado
							</Typography>
						)}
						<Typography
							sx={{
								fontWeight: "bold",
							}}
						>
							{existsOrderInstallation
								? "Tiene una orden de instalación ejecutada."
								: "Aún no existe una orden de instalación."}
						</Typography>
					</Box>
				</Box>
				{!existsOrderInstallation && (
					<Button
						variant="contained"
						color="primary"
						onClick={() => navigateToUrl("/#/orders")}
						size="small"
					>
						Crear orden servicio
					</Button>
				)}
			</Box>
		</Grid>
	);
};

export default OrderInstallation;
