import { z } from "zod";

export const PatchEmitDiagnosticSchemaSolar = z.object({
	diagnostic: z.object({
		measurement_type: z.string().min(1, "Este campo es obligatorio."),
		comunication: z.string().min(1, "Este campo es obligatorio."),
		opening: z.string().min(1, "Este campo es obligatorio."),
		main_meter: z.string().min(1, "Este campo es obligatorio."),
		test_block: z.string().min(1, "Este campo es obligatorio."),
		current_transformer: z.string().min(1, "Este campo es obligatorio."),
		voltage_transformer: z.string().min(1, "Este campo es obligatorio."),
		box: z.string().min(1, "Este campo es obligatorio."),
		control_wire: z.string().min(1, "Este campo es obligatorio."),
		other: z.string().min(1, "Este campo es obligatorio."),
		observation_work_order: z.string().min(1, "Este campo es obligatorio."),
		status: z.string().min(1, "Este campo es obligatorio."),
		of_type: z.string(),
	}),
	materials_and_labor: z.object({}),
	emited_by: z.object({}).nullable(),
});
