import type { FC } from "react";
import { ComponentMap } from ".";
import type { IDynamicForm } from "../../interfaces";

export const FormItem: FC<IDynamicForm> = (field) => {
	const { type } = field;
	const Component = ComponentMap[type] as React.FC<IDynamicForm>;

	return <Component {...field} />;
};
