import {
	Grid,
	LoadingButton,
	api,
	decodedJwt,
	useSnackbar,
} from "@enerbit/base";
import { zodResolver } from "@hookform/resolvers/zod";
import { type FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { EmissionDialog } from ".";
import { formDiagnostic } from "../../form-builders";
import {
	calculateTotal,
	formatPrice,
	restoreTransformMaterials,
} from "../../helpers";
import type { IDiagnosticFormData } from "../../interfaces";
import { PatchEmitDiagnosticSchema } from "../../schemas";
import { useEmissionStore } from "../../store";
import { FormItem } from "../dynamic-form";

interface Props {
	handleClose: () => void;
}

export const PatchEmitDiagnosticForm: FC<Props> = ({ handleClose }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [isStatusChanged, setIsStatusChanged] = useState(false);

	const { enqueueSnackbar } = useSnackbar();

	const { materials, previous_diagnostic, updatePreviousDiagnostic } =
		useEmissionStore();

	const total = calculateTotal(materials);

	const getUserInfo = async () => {
		const res = await decodedJwt();
		return {
			name: res?.full_name ?? "",
			email: res?.name ?? "",
			operator_id: res?.sub ?? "",
		};
	};

	const methods = useForm<IDiagnosticFormData>({
		resolver: zodResolver(PatchEmitDiagnosticSchema),
		defaultValues: {
			diagnostic: {
				...previous_diagnostic?.form_data.diagnostic,
				reasons_update: "",
				of_type: "field",
			},
			materials_and_labor: {
				materials: restoreTransformMaterials(materials),
				total_price: formatPrice(total),
			},
			emited_by: null,
		},
		mode: "onChange",
	});

	const { getValues, watch, trigger, setValue } = methods;
	const status = watch("diagnostic.status");
	const measurementType = watch("diagnostic.measurement_type");

	useEffect(() => {
		if (measurementType === "semidirecta" || measurementType === "indirecta") {
			setValue("diagnostic.current_transformer", "Aplica");
		} else {
			setValue("diagnostic.current_transformer", null);
		}
		if (measurementType === "indirecta") {
			setValue("diagnostic.voltage_transformer", "Aplica");
		} else {
			setValue("diagnostic.voltage_transformer", null);
		}
	}, [measurementType, setValue]);

	useEffect(() => {
		if (status === "ok") {
			setValue("diagnostic.comment_or", "");
			setValue("diagnostic.observation_work_order", "");
			setValue("diagnostic.comment_bussiness", null);
		} else {
			setValue("diagnostic.comment_or", null);
			setValue("diagnostic.observation_work_order", null);
			setValue("diagnostic.comment_bussiness", "");
		}
	}, [status, setValue]);

	const handleOpenDialog = async () => {
		if (await trigger()) setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleConfirmDialog = async () => {
		if (await trigger()) {
			const values = getValues();
			await onSubmit(values);
			handleCloseDialog();
		}
	};

	const onSubmit = async (data: IDiagnosticFormData) => {
		try {
			setIsLoading(true);
			const userInfo = await getUserInfo();
			const diagnostic: IDiagnosticFormData = {
				...data,
				emited_by: userInfo,
			};
			await api.patch(
				`/frontier-initial-diagnostic/emit-diagnostic?_id=${previous_diagnostic?.id}`,
				diagnostic,
			);
			updatePreviousDiagnostic(diagnostic);
			enqueueSnackbar("Diagnóstico editado con éxito.", {
				variant: "success",
			});
			handleClose();
		} catch (error) {
			enqueueSnackbar("No se pudo editar el diagnóstico, inténtelo de nuevo.", {
				variant: "error",
			});
		} finally {
			setIsLoading(false);
		}
	};

	const statusValue = watch("diagnostic.status");

	useEffect(() => {
		if (previous_diagnostic?.form_data.diagnostic.status !== statusValue) {
			setIsStatusChanged(true);
		}
	}, [previous_diagnostic?.form_data.diagnostic.status, statusValue]);

	return (
		<FormProvider {...methods}>
			<form onSubmit={(e) => e.preventDefault()}>
				<Grid container spacing={3}>
					{formDiagnostic.map((item, index) => {
						const isDisabled =
							item.name === "status" &&
							previous_diagnostic?.form_data.diagnostic.status === "ok";
						const newItem = {
							...item,
							name: `diagnostic.${item.name}`,
							...(item.name === "status" && {
								props: {
									disabled: isDisabled,
								},
							}),
						};
						return <FormItem {...newItem} key={index.toString()} />;
					})}
					{(measurementType === "semidirecta" ||
						measurementType === "indirecta") && (
						<Grid item xs={12}>
							<FormItem
								name="diagnostic.current_transformer"
								label="Transformadores de corriente"
								type="text"
								sx={{ xs: 12, md: 6, lg: 4 }}
							/>
						</Grid>
					)}
					{measurementType === "indirecta" && (
						<Grid item xs={12}>
							<FormItem
								name="diagnostic.voltage_transformer"
								label="Transformadores de tensión"
								type="text"
								sx={{ xs: 12, md: 6, lg: 4 }}
							/>
						</Grid>
					)}
					{status === "ok" ? (
						<>
							<Grid item xs={12}>
								<FormItem
									name="diagnostic.comment_or"
									label="Comentarios para el OR"
									type="text-area"
									sx={{ xs: 12 }}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormItem
									name="diagnostic.observation_work_order"
									label="Observación para orden de servicio"
									type="text-area"
									sx={{ xs: 12 }}
								/>
							</Grid>
						</>
					) : (
						<Grid item xs={12}>
							<FormItem
								name="diagnostic.comment_bussiness"
								label="Comentarios comercial"
								type="text-area"
								sx={{ xs: 12 }}
							/>
						</Grid>
					)}
					<FormItem
						type={"text-area"}
						name="diagnostic.reasons_update"
						label="Razones de la actualización"
						sx={{ xs: 12 }}
					/>
					<Grid item xs={12}>
						<LoadingButton
							loading={isLoading}
							onClick={
								watch("diagnostic.status") === "ok"
									? handleOpenDialog
									: handleConfirmDialog
							}
							color="secondary"
							variant="contained"
							fullWidth
						>
							Editar
						</LoadingButton>
					</Grid>
				</Grid>
			</form>
			<EmissionDialog
				open={openDialog}
				onClose={handleCloseDialog}
				onConfirm={handleConfirmDialog}
			/>
		</FormProvider>
	);
};
