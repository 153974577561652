import { NotiProvider, ThemeConfig, initEnviroment } from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import { MainLayout } from "./layouts";
import { ViabilityDiagnostic } from "./pages/ViabilityDiagnostic";

initEnviroment({
	baseUrl: process.env.REACT_APP_BASE_URL,
	geopoliticsBaseUrl: process.env.REACT_APP_BASE_GEOPOLITICS_URL,
});

export default hot(function Root() {
	return (
		<ThemeConfig>
			<NotiProvider>
				<MainLayout>
					<ViabilityDiagnostic />
				</MainLayout>
			</NotiProvider>
		</ThemeConfig>
	);
});
