import {
	AddCircleOutlineIcon,
	Box,
	Button,
	Chip,
	Grid,
	InfoOutlinedIcon,
	Typography,
	enerbitColors,
} from "@enerbit/base";
import { useEffect, useState } from "react";
import {
	AddMaterialModal,
	MaterialLaborTable,
	ViabilityError,
	ViabilityStatus,
} from ".";
import { formatPrice } from "../../helpers";
import { calculateTotal } from "../../helpers/material-labor";
import { useEmissionStore } from "../../store";

export const MaterialLabor = () => {
	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const { materials, installation } = useEmissionStore();
	const total = calculateTotal(materials);
	const formattedTotal = formatPrice(total);

	return (
		<Grid container spacing={3}>
			{installation ? (
				<ViabilityStatus status={total <= installation.totalInstallation} />
			) : (
				<ViabilityError />
			)}
			<MaterialLaborTable />
			<Grid item xs={12}>
				<Box sx={{ display: "flex", justifyContent: "end" }}>
					<Button
						color="primary"
						variant="outlined"
						startIcon={<AddCircleOutlineIcon />}
						size="small"
						onClick={handleOpen}
					>
						Agregar nuevo campo
					</Button>
					{open && <AddMaterialModal open={open} handleClose={handleClose} />}
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					<Box>
						<Typography fontSize={"24px"} color={enerbitColors.primary.main}>
							Total:
						</Typography>
						<Chip
							icon={<InfoOutlinedIcon fontSize="small" />}
							label="Recuerda que el valor total con IVA incluido"
							sx={{
								color: enerbitColors.neutral.main,
								backgroundColor: "white",
							}}
						/>
					</Box>
					<Typography
						fontWeight={"bold"}
						fontSize={"28px"}
						color={enerbitColors.primary.main}
					>
						{formattedTotal}
					</Typography>
				</Box>
			</Grid>
		</Grid>
	);
};
