import {
	Box,
	Button,
	CheckCircleIcon,
	Grid,
	Typography,
	WarningIcon,
} from "@enerbit/base";
import { type FC, useState } from "react";
import { InstallationCostsModal } from "./InstallationCostsModal";

interface Props {
	status: boolean;
}

export const ViabilityStatus: FC<Props> = ({ status }) => {
	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Grid item xs={12}>
			<Box
				sx={{
					borderRadius: "12px",
					border: `1px solid ${status ? "#12B76A" : "#F79009"}`,
					backgroundColor: status ? "#E7F8F0" : "#FEF4E6",
					display: "flex",
					justifyContent: "space-between",
					width: "100%",
					gap: 4,
					p: 2,
				}}
			>
				<Box sx={{ display: "flex", gap: 4, alignItems: "center" }}>
					{status ? (
						<CheckCircleIcon fontSize="large" color="success" />
					) : (
						<WarningIcon fontSize="large" color="warning" />
					)}

					<Box>
						<Typography
							fontSize={"18px"}
							fontWeight={"bold"}
							color={status ? "#12B76A" : ""}
						>
							{status ? "Viable" : "Pendiente de adecuaciones"}
						</Typography>
						<Typography fontWeight={"bold"} color={"#667085"}>
							{status
								? "El cliente cumple con todos los requisitos necesarios"
								: "Se excede el valor del costo de instalación"}
						</Typography>
					</Box>
				</Box>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Button
						color="primary"
						variant="contained"
						size="small"
						onClick={handleOpen}
					>
						Costos de instalación
					</Button>
					<InstallationCostsModal open={open} handleClose={handleClose} />
				</Box>
			</Box>
		</Grid>
	);
};
