import { Box, Grid, Typography } from "@enerbit/base";
import type { FC } from "react";

interface Props {
	message: string;
}

export const EmptyData: FC<Props> = ({ message }) => {
	return (
		<Box
			p={4}
			sx={{ border: "1px solid #D0D5DD", borderRadius: "12px" }}
			width="100%"
		>
			<Grid container spacing={4} display="flex" justifyContent="center">
				<Grid item>
					<img
						src={
							"https://ebdevmicrofront.blob.core.windows.net/resources/bombi-sad.webp"
						}
						alt="sad-bombi"
					/>
				</Grid>
				<Grid item display="flex" alignItems="center">
					<Typography
						color="#344054"
						fontWeight="700"
						lineHeight="19px"
						letterSpacing="-0.02em"
					>
						{message}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};
