import { DataGrid, darken, styled } from "@enerbit/base";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	border: "none",
	"& .MuiDataGrid-columnSeparator": {
		display: "none",
	},
	"& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
		outline: "none !important",
	},
	"& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
		{
			outline: "none !important",
		},
	"& .MuiDataGrid-virtualScrollerRenderZone": {
		display: "block",
		position: "static",
	},
	"&>.MuiDataGrid-main": {
		"&>.MuiDataGrid-columnHeaders": {
			borderBottom: "none",
		},
	},
	"& .MuiDataGrid-cell": {
		borderBottom: "none",
	},
	"& .MuiDataGrid-row": {
		width: "100%",
		cursor: "pointer",
		border: "none",
		borderRadius: "12px",
		marginTop: theme.spacing(0.5),
		marginBottom: theme.spacing(0.5),
		backgroundColor: "#F2F4F7",
		"&:hover": {
			backgroundColor: darken("#F2F4F7", 0.06),
		},
	},
	"& .MuiDataGrid-row.Mui-selected": {
		backgroundColor: "#EFE9FB",
		"&:hover": {
			backgroundColor: darken("#EFE9FB", 0.06),
		},
	},
	"& .MuiDataGrid-cell:focus": {
		outline: "none",
	},
	"& .MuiDataGrid-cell:focus-within": {
		outline: "none",
	},
	"& .MuiDataGrid-footerContainer": {
		justifyContent: "center",
		borderTop: "none",
	},
	"& .MuiDataGrid-columnHeaderTitle": {
		textOverflow: "inherit",
		fontWeight: "bold",
	},
	"& .MuiPaginationItem-root": {
		borderRadius: "8px",
	},
}));
