import type { ILeadItem } from "../../../interfaces";
import type { IIncomingDataSolar } from "../../../interfaces/IEmissionStoreSolar";
import type { IInputDataFormDataSolar } from "../../../interfaces/solar/IRespOutputDataSolar";
import { generateHeaderSolar } from "../generateSolar";
import {
	generateAccessInstallationSolar,
	generateDataTelemetrySolar,
	generateObservations,
	generateSfvInformationSolar,
} from "../generateSolarData";

export const generateIncomingDataSolar = (
	lead: ILeadItem,
	createdAt: Date,
	formData?: IInputDataFormDataSolar,
): IIncomingDataSolar => {
	return {
		header: generateHeaderSolar(lead, createdAt),
		sfvInformation: generateSfvInformationSolar(lead, formData),
		accessInstallation: generateAccessInstallationSolar(lead, formData),
		observations: generateObservations(lead, formData),
		dataTelemetry: generateDataTelemetrySolar(lead, formData),
	};
};
