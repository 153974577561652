import { Pagination } from "@enerbit/base";

export const PendingReviewPagination = () => {
	return (
		<Pagination
			disabled={false}
			shape="rounded"
			color="primary"
			count={1}
			page={1}
			onChange={(_, value) => null}
		/>
	);
};
