import type { IField, IIncomingDataItem } from "../../interfaces";

export const generateTerminalsID = (field: IField): IIncomingDataItem[] => {
	const items = [
		{
			field: "Existe bornera de pruebas",
			value: field?.input_data.form_data.terminals ? "Si" : "No",
			description: "Bornera de pruebas",
		},
		...(field.input_data.form_data.terminals?.map(({ manufacturer }, index) => {
			let manufacturerValue = "";
			if (typeof manufacturer === "string") {
				manufacturerValue = manufacturer;
			} else if (
				manufacturer &&
				typeof manufacturer === "object" &&
				"value" in manufacturer
			) {
				manufacturerValue = manufacturer.value;
			}

			return {
				field: `Marca bloque de pruebas n° ${index + 1}`,
				value: manufacturerValue,
				description: `Marca bloque de pruebas n° ${index + 1}`,
			};
		}) ?? []),
	];

	const filteredItems = items.filter((item) => item.value !== "");

	return filteredItems;
};
