import { Box, Button, Grid, enerbitColors } from "@enerbit/base";
import { type SyntheticEvent, useCallback, useEffect, useState } from "react";
import {
	Concept,
	DocumentaryCompliance,
	IncomingData,
	MaterialLabor,
	MeasurementSystem,
	Photographs,
} from ".";
import { StyledTab, StyledTabs, TabPanel } from "../../common";
import { OrderTypes } from "../../enums/solar/ordersType";
import { useEmissionStore, useTableStore } from "../../store";
import { PhotographsSolar } from "./PhotographsSolar";
import { ConceptSolar } from "./solar/ConceptSolar";
import { EditableSolarData } from "./solar/EditableSolarData";
import { IncomingDataSolar } from "./solar/IncomingDataSolar";
import { MaterialLaborSolar } from "./solar/MaterialLaborSolar";

const a11yProps = (index: number) => {
	return {
		id: `tab-${index}`,
		"aria-controls": `subtabpanel-${index}`,
	};
};

interface IEmissionTabsProps {
	isSolar?: boolean;
}

export const EmissionTabs = ({ isSolar }: IEmissionTabsProps) => {
	const {
		measurementSystem,
		calculateViability,
		materials,
		documentaryCompliance,
	} = useEmissionStore();
	const { listLeads, addedEntities, diagnosticType } = useTableStore();
	const [value, setValue] = useState(0);

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const calculateLeadViability = useCallback(() => {
		const lead = listLeads.find(({ id }) => addedEntities[0].id === id);
		if (lead) {
			calculateViability(lead);
		}
	}, [listLeads, addedEntities, calculateViability]);

	useEffect(() => {
		calculateLeadViability();
	}, [calculateLeadViability, materials]);

	return (
		<Grid item xs={12}>
			<Box
				sx={{
					borderRadius: "16px",
					border: `1px solid ${enerbitColors.neutral[300]}`,
				}}
			>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<StyledTabs
						value={value}
						onChange={handleChange}
						aria-label="subtabs emission"
					>
						<StyledTab label="Datos de entrada" {...a11yProps(0)} />

						{isSolar && <StyledTab label="Datos editables" {...a11yProps(1)} />}

						<StyledTab
							label="Sistema de medida"
							{...a11yProps(isSolar ? 2 : 1)}
							sx={{ display: !measurementSystem ? "none" : "flex" }}
						/>
						<StyledTab
							label="Cumplimiento documental"
							{...a11yProps(isSolar ? 3 : 2)}
							sx={{
								display:
									!documentaryCompliance || documentaryCompliance?.length === 0
										? "none"
										: "flex",
							}}
						/>
						<StyledTab label="Fotografías" {...a11yProps(isSolar ? 4 : 3)} />
						<StyledTab
							label={
								<Button color="secondary" variant="outlined">
									Material y mano de obra
								</Button>
							}
							{...a11yProps(isSolar ? 5 : 4)}
						/>
						<StyledTab
							label={
								<Button color="secondary" variant="outlined">
									Concepto
								</Button>
							}
							{...a11yProps(isSolar ? 4 : 5)}
						/>
					</StyledTabs>
				</Box>
				<TabPanel value={value} index={0} sx={{ px: 4, pb: 4 }}>
					{diagnosticType === OrderTypes.solar && <IncomingDataSolar />}
					{diagnosticType === OrderTypes.conventional && <IncomingData />}
				</TabPanel>
				{isSolar && (
					<TabPanel
						value={value}
						index={1}
						sx={{
							px: 4,
							pb: 4,
							display: !measurementSystem ? "none" : "block",
						}}
					>
						<EditableSolarData />
					</TabPanel>
				)}
				<TabPanel
					value={value}
					index={isSolar ? 2 : 1}
					sx={{ px: 4, pb: 4, display: !measurementSystem ? "none" : "block" }}
				>
					<MeasurementSystem />
				</TabPanel>
				<TabPanel
					value={value}
					index={isSolar ? 3 : 2}
					sx={{
						px: 4,
						pb: 4,
						display:
							!documentaryCompliance || documentaryCompliance?.length === 0
								? "none"
								: "block",
					}}
				>
					<DocumentaryCompliance />
				</TabPanel>
				<TabPanel value={value} index={isSolar ? 4 : 3} sx={{ px: 4, pb: 4 }}>
					{diagnosticType === OrderTypes.solar ? (
						<PhotographsSolar />
					) : (
						<Photographs />
					)}
				</TabPanel>
				<TabPanel value={value} index={isSolar ? 5 : 4} sx={{ px: 4, pb: 4 }}>
					{diagnosticType === OrderTypes.solar ? (
						<MaterialLaborSolar />
					) : (
						<MaterialLabor />
					)}
				</TabPanel>
				<TabPanel value={value} index={isSolar ? 6 : 5} sx={{ px: 4, pb: 4 }}>
					{diagnosticType === OrderTypes.solar ? <ConceptSolar /> : <Concept />}
				</TabPanel>
			</Box>
		</Grid>
	);
};
