import { Box, Divider, Grid, Typography, enerbitColors } from "@enerbit/base";
import type { FC } from "react";
import { formatPrice } from "../../helpers";
import type { IInstallationItem } from "../../interfaces";
import { CostItem } from "./CostItem";

interface Props {
	installation: IInstallationItem;
	size: number;
}
export const InstallationItem: FC<Props> = ({ size, installation }) => {
	const { type, device, measurement, labor, previousVisit, totalInstallation } =
		installation;

	let xsSize: number;
	let lgSize: number;

	switch (size) {
		case 2:
			xsSize = 12;
			lgSize = 6;
			break;
		case 3:
			xsSize = 12;
			lgSize = 4;
			break;
		case 4:
			xsSize = 12;
			lgSize = 3;
			break;
		default:
			xsSize = 12;
			lgSize = 12;
	}

	return (
		<Grid item xs={xsSize} lg={lgSize}>
			<Box
				sx={{
					borderRadius: "16px",
					border: "1px solid #E4E7EC",
					p: 2,
					gap: 2,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Typography
					fontWeight={"bold"}
					color={enerbitColors.primary.main}
					fontSize={"16px"}
				>
					{type}
				</Typography>
				<Box>
					<CostItem title={"Dispositivos"} value={device} />
					<CostItem title={"Medida"} value={measurement} />
					<CostItem title={"Mano de obra"} value={labor} />
					<CostItem title={"Visitas previas"} value={previousVisit} />
				</Box>
				<Divider />
				<Box
					sx={{
						backgroundColor: enerbitColors.primary.main,
						borderRadius: "8px",
						p: 1,
						display: "flex",
						justifyContent: "space-between",
						gap: 2,
						width: "100%",
					}}
				>
					<Typography color={"white"}>Total</Typography>
					<Typography color={"white"} fontWeight={"bold"}>
						{formatPrice(totalInstallation)}
					</Typography>
				</Box>
			</Box>
		</Grid>
	);
};
