import { capitalize } from "@enerbit/base";
import type { IField, IIncomingDataItem } from "../../interfaces";

export const generateMainMeterID = (field: IField): IIncomingDataItem[] => {
	const items = [
		{
			field: "Ubicación del medidor",
			value: capitalize(
				field?.input_data.form_data.main_meter?.meter_location ?? "",
			),
			description: "Ubicación del medidor",
		},
		{
			field: "Altura a la que se encuentra el medidor instalado (cms)",
			value:
				field?.input_data.form_data.main_meter?.height_meter_installed ?? "",
			description: "Altura a la que se encuentra el medidor instalado (cms)",
		},
	];

	const filteredItems = items.filter((item) => item.value !== "");

	return filteredItems;
};
