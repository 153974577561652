import { Pagination } from "@enerbit/base";
import { EEntity } from "../../interfaces";
import { useTableStore } from "../../store";

export const AddressPagination = () => {
	const { rowCount, page, loading, entity, setPage } = useTableStore();

	return (
		<Pagination
			disabled={loading}
			shape="rounded"
			color="primary"
			count={rowCount}
			page={entity === EEntity.Customer ? page + 1 : page}
			onChange={(_, value) =>
				setPage(entity === EEntity.Customer ? value - 1 : value)
			}
		/>
	);
};
