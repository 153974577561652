import type { IField, IIncomingDataItem } from "../../interfaces";

export const generateElectricalConductorsID = (
	field: IField,
): IIncomingDataItem[] => {
	const items = [
		{
			field: "Existe cableado",
			value: field?.input_data.form_data.electrical_conductors ? "Si" : "No",
			description: "Cableado de conductores eléctricos",
		},
	];

	const filteredItems = items.filter((item) => item.value !== "");

	return filteredItems;
};
