import { Button, Grid, type GridColDef, Stack } from "@enerbit/base";
import { useState } from "react";
import { EmissionTable } from ".";
import { useEmissionStore } from "../../store";
import { EmitDiagnosticModal } from "./EmitDiagnosticModal";

const columns: GridColDef[] = [
	{
		field: "field",
		headerClassName: "custom-header",
		headerName: "Nombre del campo",
		flex: 1,
		align: "left",
		headerAlign: "left",
		sortable: false,
		disableColumnMenu: true,
	},
	{
		field: "observations",
		headerClassName: "custom-header",
		headerName: "Observaciones",
		flex: 3,
		align: "left",
		headerAlign: "left",

		sortable: false,
		disableColumnMenu: true,
	},
];

export const Concept = () => {
	const { concept, previous_diagnostic } = useEmissionStore();

	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Grid container spacing={3}>
			<EmissionTable
				rows={concept.map((item, index) => ({ ...item, id: index }))}
				title="Comentarios"
				columns={columns}
			/>

			{previous_diagnostic?.form_data.diagnostic.status !== "ok" && (
				<Grid item xs={12}>
					<Stack direction={"row"} spacing={3}>
						<Button
							color="secondary"
							variant="contained"
							fullWidth
							onClick={handleOpen}
						>
							{previous_diagnostic
								? "Actualizar diagnóstico"
								: "Emitir diagnóstico"}
						</Button>
						<EmitDiagnosticModal open={open} handleClose={handleClose} />
					</Stack>
				</Grid>
			)}
		</Grid>
	);
};
