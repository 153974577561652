import type {
	IMeasurementSystem,
	IMeasurementSystemItem,
	IVoltageTransformer,
} from "../../interfaces";

export const generateVoltageTransformerMS = (
	measurement_system?: IMeasurementSystem,
	voltage_transformer?: IVoltageTransformer | null,
): IMeasurementSystemItem[] => {
	if (!measurement_system || !voltage_transformer) return [];

	const items = [
		{
			field: "Clase de exactitud (%)",
			currentValue:
				measurement_system?.voltage_transformer_accuracy_class
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.voltage_transformer_accuracy_class
					?.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.voltage_transformer_accuracy_class?.validation,
		},
		{
			field: "Ubicación de los transformadores de tensión",
			currentValue:
				measurement_system?.voltage_transformer_location
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.voltage_transformer_location
					?.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.voltage_transformer_location?.validation,
		},
		{
			field: "Tipo exterior o interior",
			currentValue:
				measurement_system?.voltage_transformer_is_exterior
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.voltage_transformer_is_exterior
					?.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.voltage_transformer_is_exterior?.validation,
		},
		{
			field: "Tensión secundaria nominal (A)",
			currentValue:
				measurement_system?.voltage_transformer_rated_secondary_current
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.voltage_transformer_rated_secondary_current
					?.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.voltage_transformer_rated_secondary_current
					?.validation,
		},
		{
			field: "Carga transformadores de tensión (VA)",
			currentValue:
				measurement_system?.voltage_transformer_load?.current_value_situation ??
				"",
			realValue:
				measurement_system?.voltage_transformer_load?.ideal_value_situation ??
				"",
			verification: !!measurement_system?.voltage_transformer_load?.validation,
		},
		{
			field: "Burden transformadores de tensión (VA)",
			currentValue:
				measurement_system?.voltage_transformer_burden
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.voltage_transformer_burden?.ideal_value_situation ??
				"",
			verification:
				!!measurement_system?.voltage_transformer_burden?.validation,
		},
		{
			field: "Cargas para compensación de burden (VA)",
			currentValue:
				measurement_system?.voltage_transformer_charges_burden_compensation
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.voltage_transformer_charges_burden_compensation
					?.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.voltage_transformer_charges_burden_compensation
					?.validation,
		},
		{
			field: "Carga calculada transformadores de tensión [%]",
			currentValue:
				measurement_system?.voltage_transformer_calculated_load
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.voltage_transformer_calculated_load
					?.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.voltage_transformer_calculated_load?.validation,
		},
		{
			field: "Corriente a un factor de potencia 0,9 (A)",
			currentValue:
				measurement_system?.voltage_transformer_current_power_factor
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.voltage_transformer_current_power_factor
					?.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.voltage_transformer_current_power_factor
					?.validation,
		},
		{
			field: "Tensión del cable de control (V)",
			currentValue:
				measurement_system?.voltage_transformer_control_cable_voltage
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.voltage_transformer_control_cable_voltage
					?.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.voltage_transformer_control_cable_voltage
					?.validation,
		},
		{
			field: "Regulación de tensión (%)",
			currentValue:
				measurement_system?.voltage_transformer_voltage_regulation
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.voltage_transformer_voltage_regulation
					?.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.voltage_transformer_voltage_regulation
					?.validation,
		},
	];

	const filteredItems = items.filter(
		(item) => item.currentValue !== "" || item.realValue !== "",
	);

	return filteredItems;
};
