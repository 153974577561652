import {
	Box,
	Button,
	CheckCircleIcon,
	DialogContent,
	Divider,
	ErrorOutlineIcon,
	Grid,
	Typography,
	WarningIcon,
	enerbitColors,
} from "@enerbit/base";
import moment from "moment";
import { useEffect, useState } from "react";
import { StyledDialog, StyledDialogTitle } from "../../../common";
import { OrderTypes } from "../../../enums/solar/ordersType";
import type { IConceptExtended } from "../../../interfaces";
import { useDownloadStore, useTableStore } from "../../../store";
import { useEmissionStoreSolar } from "../../../store/emission-solar";
import { DownloadItem } from "../../consult-download";

export const LastDiagnosticSolar = () => {
	const { previous_diagnostic } = useEmissionStoreSolar();

	const { addedEntities, diagnosticType } = useTableStore();

	const { technical, business, getConcepts } = useDownloadStore();

	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	useEffect(() => {
		if (addedEntities.length > 0) {
			getConcepts(addedEntities[0].id, diagnosticType === OrderTypes.solar);
		}
	}, [addedEntities, diagnosticType, getConcepts]);

	if (!previous_diagnostic) return null;

	const { created_at, form_data } = previous_diagnostic;

	const statusColor =
		form_data.diagnostic.status === "ok"
			? "success"
			: form_data.diagnostic.status === "pending"
				? "warning"
				: "error";
	const StatusIcon =
		form_data.diagnostic.status === "ok"
			? CheckCircleIcon
			: form_data.diagnostic.status === "pending"
				? WarningIcon
				: ErrorOutlineIcon;

	return (
		<Grid item xs={12}>
			<Box
				sx={{
					borderRadius: "12px",
					backgroundColor: "#EEEBF4",
					display: "flex",
					justifyContent: "space-between",
					px: 4,
					py: 2,
				}}
			>
				<Box>
					<Typography
						sx={{
							color: enerbitColors.primary.main,
							fontSize: "18px",
							fontWeight: "bold",
						}}
					>
						Último diagnóstico emitido
					</Typography>
					<Box sx={{ display: "flex", gap: 2 }}>
						<Typography>
							Fecha: <br /> <b>{moment(created_at).format("YYYY-MM-DD")}</b>
						</Typography>
						<Divider orientation="vertical" variant="middle" flexItem />

						<Typography>
							Responsable: <br /> <b>{form_data.emited_by?.email}</b>
						</Typography>
						<Divider orientation="vertical" variant="middle" flexItem />
						<Box>
							Estado:
							<Box sx={{ display: "flex", alignItems: "start", gap: 1 }}>
								<StatusIcon color={statusColor} sx={{ fontSize: "18px" }} />
								<Typography sx={{ fontWeight: "bold" }}>
									{form_data.diagnostic.status === "ok"
										? "Viable"
										: form_data.diagnostic.status === "pending"
											? "Pendiente de adecuaciones"
											: "No viable"}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Button
						color="primary"
						variant="outlined"
						size="large"
						onClick={handleOpen}
					>
						Ver detalle
					</Button>
				</Box>
			</Box>
			<StyledDialog open={open} fullWidth onClose={handleClose} maxWidth="md">
				<StyledDialogTitle onClose={handleClose} id={"id-confirm-edit"}>
					Detalle del último diagnóstico emitido
				</StyledDialogTitle>
				<DialogContent>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Box sx={{ display: "flex", gap: 2 }}>
								<Typography>
									Fecha: <br /> <b>{moment(created_at).format("YYYY-MM-DD")}</b>
								</Typography>
								<Divider orientation="vertical" variant="middle" flexItem />

								<Typography>
									Responsable: <br /> <b>{form_data.emited_by?.email}</b>
								</Typography>
								<Divider orientation="vertical" variant="middle" flexItem />
								<Box>
									Estado:
									<Box sx={{ display: "flex", alignItems: "start", gap: 1 }}>
										<StatusIcon color={statusColor} sx={{ fontSize: "18px" }} />
										<Typography sx={{ fontWeight: "bold" }}>
											{form_data.diagnostic.status === "ok"
												? "Viable"
												: form_data.diagnostic.status === "pending"
													? "Pendiente de adecuaciones"
													: "No viable"}
										</Typography>
									</Box>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Typography color={enerbitColors.primary.main} fontSize={"18px"}>
								Información diagnóstico emitido
							</Typography>
							<Typography>
								Medidor principal: <b>{form_data.diagnostic.main_meter}</b>
							</Typography>
							<Typography>
								Bloque de pruebas: <b>{form_data.diagnostic.test_block}</b>
							</Typography>
							<Typography>
								Comunicación: <b>{form_data.diagnostic.comunication}</b>
							</Typography>
							{form_data.diagnostic.current_transformer && (
								<Typography>
									Transformador de corriente:{" "}
									<b>{form_data.diagnostic.current_transformer}</b>
								</Typography>
							)}
							{form_data.diagnostic.voltage_transformer && (
								<Typography>
									Transformador de tensión:{" "}
									<b>{form_data.diagnostic.voltage_transformer}</b>
								</Typography>
							)}
							<Typography>
								Paneles o cajas: <b>{form_data.diagnostic.box}</b>
							</Typography>
							<Typography>
								Cable de control: <b>{form_data.diagnostic.control_wire}</b>
							</Typography>
							<Typography>
								Otro: <b>{form_data.diagnostic.other}</b>
							</Typography>
							<Typography>
								Apertura: <b>{form_data.diagnostic.opening}</b>
							</Typography>
						</Grid>
						<DownloadItem
							concepts={
								[
									technical
										? ({
												...technical,
												title: "Concepto técnico",
											} as IConceptExtended)
										: null,
									business
										? ({
												...business,
												title: "Concepto comercial",
											} as IConceptExtended)
										: null,
								].filter(Boolean) as IConceptExtended[]
							}
						/>
					</Grid>
				</DialogContent>
			</StyledDialog>
		</Grid>
	);
};
