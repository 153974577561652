import heic2any from "heic2any";
import type {
	IPictureArray,
	IPicturesInspection,
	ITransformerPictures,
} from "../interfaces";

export async function convertHeicToJpeg(
	pictures: IPicturesInspection,
): Promise<IPicturesInspection> {
	const convertArray = async (
		pictureArray: IPictureArray[],
	): Promise<IPictureArray[]> => {
		return Promise.all(
			pictureArray.map(async (picture) => {
				if (picture.image_url?.includes(".heic")) {
					try {
						const response = await fetch(picture.image_url);
						const blob = await response.blob();
						const newUrl = await heic2any({
							blob,
							toType: "image/jpeg",
							quality: 0.5, // ajusta la calidad según sea necesario
						});
						const newBlob = new Blob([newUrl as BlobPart]);

						const reader = new FileReader();
						const base64String: string = await new Promise(
							(resolve, reject) => {
								reader.readAsDataURL(newBlob);
								reader.onloadend = () => resolve(reader.result as string);
								reader.onerror = reject;
							},
						);

						return {
							...picture,
							image_url: base64String,
						};
					} catch (error) {
						console.error("Error converting HEIC to JPEG:", error);
						return picture; // Devuelve la imagen original en caso de error
					}
				} else {
					return picture;
				}
			}),
		);
	};

	const convertTransformerPictures = async (
		transformerPictures?: ITransformerPictures | null,
	): Promise<ITransformerPictures | null> => {
		if (!transformerPictures) return null;
		const phases = ["r_phase", "s_phase", "t_phase"] as const;
		const converted: ITransformerPictures = {};
		for (const phase of phases) {
			if (transformerPictures[phase]) {
				converted[phase] = await convertArray(
					transformerPictures[phase] as IPictureArray[],
				);
			}
		}
		return converted;
	};

	return {
		general_data: await convertArray(pictures.general_data),
		main_meter: await convertArray(pictures.main_meter),
		optional_meter: await convertArray(pictures.optional_meter),
		current_transformer: pictures.current_transformer
			? await convertArray(pictures.current_transformer)
			: undefined,
		phases_current_transformer: await convertTransformerPictures(
			pictures.phases_current_transformer,
		),
		voltage_transformer: pictures.voltage_transformer
			? await convertArray(pictures.voltage_transformer)
			: undefined,
		phases_voltage_transformer: await convertTransformerPictures(
			pictures.phases_voltage_transformer,
		),
		electrical_boxes: await convertArray(pictures.electrical_boxes),
		electrical_conductors: pictures.electrical_conductors
			? await convertArray(pictures.electrical_conductors)
			: null,
		terminals: pictures.terminals
			? await convertArray(pictures.terminals)
			: null,
		photographic_annexes: await convertArray(pictures.photographic_annexes),
	};
}
