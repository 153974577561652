import { api } from "@enerbit/base";
import type { AxiosProgressEvent, AxiosRequestConfig } from "axios";

export const uploadFile = async (
	image: File,
	onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
) => {
	const bodyFormData = new FormData();
	bodyFormData.append("file", image);

	const config: AxiosRequestConfig = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
		onUploadProgress: (progressEvent: AxiosProgressEvent) => {
			const standardProgressEvent: AxiosProgressEvent = {
				...progressEvent,
				total: progressEvent.total,
				loaded: progressEvent.loaded,
			};

			onUploadProgress(standardProgressEvent);
		},
	};

	const { data } = await api.put<{ url: string }>(
		"/lead-documentation/upload-document/",
		bodyFormData,
		config,
	);

	return data.url;
};

export const loadFile = async (url_image: string) => {
	const { data } = await api.get<{ url: string }>(
		`/lead-documentation/authenticate-file?url_path=${url_image}`,
	);
	return data.url;
};
