import { Box, Grid, HelpOutlineIcon, InputLabel, Tooltip } from "@enerbit/base";
import { Switch } from "@mui/material";
import type { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import type { IDynamicForm } from "../../../interfaces";

const SwitchForm: FC<IDynamicForm> = ({
	name,
	label,
	sx,
	props,
	tooltipInfo,
}) => {
	const {
		control,
		formState: { errors },
		watch,
		setValue,
	} = useFormContext();

	const currentValue = name ? Boolean(watch(name)) : false;

	if (!name) return null;

	return (
		<Grid item xs={sx?.xs} md={sx?.md} lg={sx?.lg}>
			<Box sx={{ display: "flex" }}>
				<InputLabel className="title-input-edition">{label}</InputLabel>
				{tooltipInfo && (
					<Tooltip
						title={<Box>{tooltipInfo}</Box>}
						enterTouchDelay={0}
						onClick={(e) => e.stopPropagation()}
						leaveTouchDelay={5000}
						componentsProps={{
							tooltip: {
								sx: {
									border: "1px solid grey",
									bgcolor: "#ffff",
									"& .MuiTooltip-arrow": {
										color: "#ffff",
									},
								},
							},
						}}
					>
						<HelpOutlineIcon
							color="primary"
							sx={{ fontSize: "20px", mr: 1.5 }}
						/>
					</Tooltip>
				)}
			</Box>
			<Controller
				name={name}
				control={control}
				defaultValue={""}
				render={({ field: { onChange, value, ...field } }) => (
					<Switch
						{...field}
						id={name}
						disabled={props?.disabled}
						onChange={() => {
							setValue(name, !currentValue);
						}}
						value={currentValue}
						checked={!!currentValue}
						sx={{ "& fieldset": { borderRadius: "14px" } }}
						color="secondary"
					/>
				)}
			/>
		</Grid>
	);
};

export default SwitchForm;
