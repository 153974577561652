import {
	generateCurrentTransformerMS,
	generateElectricalConductorsMS,
	generateMainMeterMS,
	generateOptionalMeter,
	generateVoltageTransformerMS,
} from ".";
import type { IField, IMeasurementSystemItem } from "../../interfaces";

export const generateMeasurementSystem = (
	field: IField,
): Record<string, IMeasurementSystemItem[]> | null => {
	if (!field.measurement_system) return null;

	return {
		mainMeter: generateMainMeterMS(field.measurement_system),
		optionalMeter: generateOptionalMeter(
			field.measurement_system,
			field.input_data.form_data.optional_meter,
		),
		currentTransformer: generateCurrentTransformerMS(
			field.measurement_system,
			field.input_data.form_data.current_transformer,
		),
		voltageTransformer: generateVoltageTransformerMS(
			field.measurement_system,
			field.input_data.form_data.voltage_transformer,
		),
		electricalConductors: generateElectricalConductorsMS(
			field.measurement_system,
			field.input_data.form_data.electrical_conductors,
		),
	};
};
