import {
	Box,
	CloseIcon,
	DeleteIcon,
	EditIcon,
	Grid,
	type GridColDef,
	type GridRenderCellParams,
	IconButton,
	MenuItem,
	Select,
	type SelectChangeEvent,
	Tooltip,
	esES,
	useGridApiContext,
} from "@enerbit/base";
import { useState } from "react";
import { StyledDataGrid } from "../../common";
import { formatPrice } from "../../helpers";
import type { IMaterial, IMaterialExtended } from "../../interfaces";
import { useEmissionStore } from "../../store";

export const MaterialLaborTable = () => {
	const [editingRow, setEditingRow] = useState<string | null>(null);

	const { materials, listQuantities, deleteMaterial, updateQuantityMaterial } =
		useEmissionStore();

	const columns: GridColDef[] = [
		{
			field: "name",
			headerClassName: "custom-header",
			headerName: "Nombre del campo",
			flex: 2,
			align: "left",
			headerAlign: "left",
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "quantity",
			headerClassName: "custom-header",
			headerName: "Cantidad",
			editable: true,
			flex: 1,
			type: "number",
			align: "left",
			headerAlign: "left",
			sortable: false,
			disableColumnMenu: true,
			renderEditCell: (params) => {
				const { id, value, field } = params;
				const apiRef = useGridApiContext();

				const handleValueChange = (event: SelectChangeEvent) => {
					const newValue = event.target.value;
					apiRef.current.setEditCellValue({ id, field, value: newValue });
					apiRef.current.setCellMode(id, field, "view");
					updateQuantityMaterial(params.row.id, Number(newValue));
				};
				return (
					<Select
						fullWidth
						value={value}
						onChange={handleValueChange}
						displayEmpty
					>
						{listQuantities.map((item, index) => (
							<MenuItem value={item} key={index.toString()}>
								{item}
							</MenuItem>
						))}
					</Select>
				);
			},
		},
		{
			field: "charge",
			headerClassName: "custom-header",
			headerName: "Valor",
			type: "number",
			flex: 1,
			align: "left",
			headerAlign: "left",
			sortable: false,
			disableColumnMenu: true,
			renderCell: (
				params: GridRenderCellParams<number, IMaterial, unknown>,
			) => {
				return params.value ? formatPrice(params.value) : "";
			},
		},
		{
			field: "subtotal",
			headerClassName: "custom-header",
			headerName: "Subtotal",
			flex: 1,
			align: "left",
			headerAlign: "left",
			sortable: false,
			disableColumnMenu: true,
			renderCell: (
				params: GridRenderCellParams<number, IMaterial, unknown>,
			) => {
				return params.value ? formatPrice(params.value) : "";
			},
		},
		{
			field: "id",
			headerName: "",
			headerClassName: "custom-header",
			align: "left",
			headerAlign: "left",
			sortable: false,
			disableColumnMenu: true,
			renderCell: (
				params: GridRenderCellParams<string, IMaterialExtended, unknown>,
			) => {
				const { id } = params;
				const apiRef = useGridApiContext();

				if (editingRow !== id && apiRef.current.getRowMode(id) === "edit") {
					apiRef.current.setRowMode(editingRow as string, "view");
				}

				const handleEdit = () => {
					setEditingRow(id as string);
					apiRef.current.setRowMode(id, "edit");
				};

				const handleCancel = () => {
					setEditingRow(null);
					apiRef.current.setRowMode(id, "view");
				};

				return (
					<Box
						width={"100%"}
						sx={{ display: "flex", justifyContent: "center", gap: 2 }}
					>
						{apiRef.current.getRowMode(id) === "view" ? (
							<Tooltip title="Editar cantidad">
								<IconButton size="small" onClick={handleEdit} color="primary">
									<EditIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title="Cancelar edición">
								<IconButton size="small" onClick={handleCancel}>
									<CloseIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						)}
						<Tooltip title={"Eliminar"}>
							<IconButton
								color="error"
								size="small"
								onClick={() =>
									params.value ? deleteMaterial(params.value) : null
								}
							>
								<DeleteIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Box>
				);
			},
		},
	];

	return (
		<Grid item xs={12}>
			<Box
				sx={{
					width: "100%",
					"& .custom-header": {
						backgroundColor: "#EFE9FB",
						"&:first-of-type": {
							borderTopLeftRadius: "12px",
							borderBottomLeftRadius: "12px",
						},
						"&:last-child": {
							borderTopRightRadius: "12px",
							borderBottomRightRadius: "12px",
						},
					},
				}}
			>
				<StyledDataGrid
					sx={{
						"& .MuiDataGrid-virtualScrollerContent": {
							height: materials.length ? "auto!important" : "100px!important",
						},
					}}
					columns={columns}
					rows={materials}
					pageSize={100}
					autoHeight
					hideFooter
					paginationMode="client"
					localeText={esES.components.MuiDataGrid.defaultProps.localeText}
					disableSelectionOnClick
					disableColumnFilter
					disableColumnSelector
					disableColumnMenu
					editMode="row"
				/>
			</Box>
		</Grid>
	);
};
