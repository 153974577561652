import { DialogContent } from "@enerbit/base";
import type { FC } from "react";
import { PatchEmitDiagnosticForm, PostEmitDiagnosticForm } from ".";
import { StyledDialog, StyledDialogTitle } from "../../common";
import { OrderTypes } from "../../enums/solar/ordersType";
import { useEmissionStore, useTableStore } from "../../store";
import { useEmissionStoreSolar } from "../../store/emission-solar";
import { PatchEmitDiagnosticFormSolar } from "./solar/PatchEmitDiagnosticFormSolar";
import { PostEmitDiagnosticFormSolar } from "./solar/PostEmitDiagnosticFormSolar";

interface Props {
	open: boolean;
	handleClose: () => void;
}

export const EmitDiagnosticModal: FC<Props> = ({ open, handleClose }) => {
	const { previous_diagnostic } = useEmissionStore();
	const { previous_diagnostic: previous_diagnostic_solar } =
		useEmissionStoreSolar();
	const { diagnosticType } = useTableStore();

	const returnDialogContent = () => {
		if (diagnosticType === OrderTypes.conventional) {
			if (!previous_diagnostic) {
				return <PostEmitDiagnosticForm handleClose={handleClose} />;
			}
			return <PatchEmitDiagnosticForm handleClose={handleClose} />;
		}

		if (diagnosticType === OrderTypes.solar) {
			if (!previous_diagnostic_solar) {
				return <PostEmitDiagnosticFormSolar handleClose={handleClose} />;
			}
			return <PatchEmitDiagnosticFormSolar handleClose={handleClose} />;
		}
	};

	return (
		<StyledDialog open={open} fullWidth onClose={handleClose} maxWidth={"lg"}>
			<StyledDialogTitle onClose={handleClose} id={"id-confirm-emit"}>
				Diagnóstico
			</StyledDialogTitle>
			<DialogContent>{returnDialogContent()}</DialogContent>
		</StyledDialog>
	);
};
