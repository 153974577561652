import {
	AddIcon,
	Avatar,
	CloseIcon,
	ExpandCircleDownOutlinedIcon,
	Grid,
	Typography,
	enerbitColors,
} from "@enerbit/base";
import { type FC, type SyntheticEvent, useState } from "react";
import { get, useFormContext } from "react-hook-form";
import {
	StyledAccordion2,
	StyledAccordionDetails2,
	StyledAccordionSummary2,
} from "../../../common";
import type { IDynamicForm } from "../../../interfaces";
import { FormItem } from "../FormItem";

export const AccordionForm: FC<IDynamicForm> = ({ name, children, label }) => {
	const {
		formState: { errors },
		watch,
	} = useFormContext();

	const [expanded, setExpanded] = useState<string | false>(false);

	const handleChange =
		(panel: string) => (_: SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	if (!name) return null;

	return (
		<Grid item xs={12}>
			<StyledAccordion2
				expanded={expanded === name}
				onChange={handleChange(name)}
				sx={{
					borderColor: get(errors, name) ? enerbitColors.error.main : undefined,
				}}
				id={name}
			>
				<StyledAccordionSummary2
					expandIcon={
						<ExpandCircleDownOutlinedIcon
							sx={{
								fontSize: "1.5rem",
								color: get(errors, name) ? enerbitColors.error.main : undefined,
							}}
						/>
					}
				>
					<div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
						<Avatar
							color="primary"
							sx={{
								borderRadius: "50%",
								bgcolor: get(errors, name)
									? enerbitColors.error.main
									: enerbitColors.primary.main,
								width: 24,
								height: 24,
							}}
						>
							{get(errors, name) ? <CloseIcon /> : <AddIcon />}
						</Avatar>
						<Typography
							sx={{
								fontWeight: 700,
								fontSize: "18px",
								color: get(errors, name) ? enerbitColors.error.main : "#667085",
							}}
						>
							{label}
						</Typography>
					</div>
				</StyledAccordionSummary2>
				<StyledAccordionDetails2>
					<Grid container spacing={3}>
						{children?.map((item, i) => {
							const currentField = {
								...item,
								name: `${name}.${item.name}`,
							};

							return <FormItem {...currentField} key={i.toString()} />;
						})}
					</Grid>
				</StyledAccordionDetails2>
			</StyledAccordion2>
		</Grid>
	);
};
