import {
	Grid,
	type GridColDef,
	type GridRenderCellParams,
	InfoOutlinedIcon,
	Tooltip,
	enerbitColors,
	useGridApiContext,
} from "@enerbit/base";
import type { IIncomingDataItem } from "../../../interfaces";
import { useEmissionStoreSolar } from "../../../store/emission-solar";
import { EmissionTable } from "../EmissionTable";

export const IncomingDataSolar = () => {
	const { incomingDataSolar } = useEmissionStoreSolar();

	const {
		sfvInformation,
		accessInstallation,
		observations,
		dataTelemetry,
		header,
	} = incomingDataSolar;

	const columns: GridColDef[] = [
		{
			field: "field",
			headerClassName: "custom-header",
			headerName: "Nombre del campo",
			flex: 2,
			align: "left",
			headerAlign: "left",
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "value",
			headerClassName: "custom-header",
			headerName: "Valor",
			editable: true,
			flex: 1,
			align: "left",
			headerAlign: "left",
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: "description",
			headerClassName: "custom-header",
			headerName: "",
			sortable: false,
			align: "right",
			headerAlign: "right",
			disableColumnMenu: true,
			renderCell: (params: GridRenderCellParams<string, IIncomingDataItem>) => {
				const apiRef = useGridApiContext();
				const { id } = params;
				return (
					<Tooltip title={params.value}>
						<InfoOutlinedIcon
							fontSize="small"
							sx={{ color: enerbitColors.neutral[700] }}
						/>
					</Tooltip>
				);
			},
		},
	];

	return (
		<Grid container spacing={3}>
			{header.length > 0 && (
				<EmissionTable
					rows={
						header.map((item, index) => ({
							...item,
							id: index,
						})) ?? []
					}
					title="Encabezado"
					columns={columns}
				/>
			)}
			{sfvInformation.length > 0 && (
				<EmissionTable
					rows={
						sfvInformation.map((item, index) => ({
							...item,
							id: index,
						})) ?? []
					}
					title="Información general solar"
					columns={columns}
				/>
			)}
			{accessInstallation.length > 0 && (
				<EmissionTable
					rows={
						accessInstallation.map((item, index) => ({
							...item,
							id: index,
						})) ?? []
					}
					title="Acceso instalación solar"
					columns={columns}
				/>
			)}
			{dataTelemetry.length > 0 && (
				<EmissionTable
					rows={
						dataTelemetry.map((item, index) => ({
							...item,
							id: index,
						})) ?? []
					}
					title="Telemetría solar"
					columns={columns}
				/>
			)}
			{observations.length > 0 && (
				<EmissionTable
					rows={
						observations.map((item, index) => ({
							...item,
							id: index,
						})) ?? []
					}
					title="Observaciones"
					columns={columns}
				/>
			)}
		</Grid>
	);
};
