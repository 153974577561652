import {
	AddCircleOutlineIcon,
	Box,
	Button,
	CloseIcon,
	DeleteIcon,
	EditIcon,
	Grid,
	type GridColDef,
	type GridRenderCellParams,
	IconButton,
	TextField,
	Tooltip,
	esES,
	useGridApiContext,
} from "@enerbit/base";
import { type ChangeEvent, useEffect, useState } from "react";
import { StyledDataGrid } from "../../../common";
import type { IIncomingDataItemInverter } from "../../../interfaces";
import { useEmissionStoreSolar } from "../../../store/emission-solar";
import { AddInverterSolar } from "./AddInverterSolar";

export const InverterTableSolar = () => {
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [open, setOpen] = useState(false);
	const [editingRow, setEditingRow] = useState<string | null>(null);
	const {
		updateInverters,
		deleteInverter,
		formEditableData,
		invertersSolar,
		updateInvertersAll,
		updateInvertersOld,
	} = useEmissionStoreSolar();

	useEffect(() => {
		if (formEditableData?.sfv_information.installation_type === "Medidor") {
			updateInvertersAll();
		} else {
			updateInvertersOld();
		}
	}, [formEditableData?.sfv_information.installation_type]);

	const columnsInverters: GridColDef[] = [
		{
			field: "inverter_brand",
			headerClassName: "custom-header",
			headerName: "Marca inversor",
			flex: 1,
			align: "left",
			headerAlign: "left",
			sortable: false,
			disableColumnMenu: true,
			editable: true,
			renderEditCell: (params) => {
				const { id, field } = params;
				const apiRef = useGridApiContext();

				const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
					const newValue = event.target.value;
					apiRef.current.setEditCellValue({ id, field, value: newValue });
					updateInverters(newValue, Number(id), params.field);
				};

				return (
					<TextField
						variant="outlined"
						InputLabelProps={{ shrink: false }}
						onChange={handleValueChange}
					/>
				);
			},
		},
		{
			field: "inverter_model",
			headerClassName: "custom-header",
			headerName: "Modelo inversor",
			editable: true,
			flex: 1,
			align: "left",
			headerAlign: "left",
			sortable: false,
			disableColumnMenu: true,
			renderEditCell: (params) => {
				const { id, field } = params;
				const apiRef = useGridApiContext();
				const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
					const newValue = event.target.value;
					apiRef.current.setEditCellValue({ id, field, value: newValue });
					updateInverters(newValue, Number(id), params.field);
				};

				return (
					<TextField
						variant="outlined"
						InputLabelProps={{ shrink: false }}
						onChange={handleValueChange}
					/>
				);
			},
		},
		{
			field: "inverter_serial",
			headerClassName: "custom-header",
			headerName: "Serial inversor",
			editable: true,
			flex: 1,
			align: "left",
			headerAlign: "left",
			sortable: false,
			disableColumnMenu: true,
			renderEditCell: (params) => {
				const { id, field } = params;
				const apiRef = useGridApiContext();

				const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
					const newValue = event.target.value;
					apiRef.current.setEditCellValue({ id, field, value: newValue });
					updateInverters(newValue, Number(id), params.field);
				};

				return (
					<TextField
						variant="outlined"
						InputLabelProps={{ shrink: false }}
						onChange={handleValueChange}
					/>
				);
			},
		},
		{
			field: "id",
			headerClassName: "custom-header",
			headerName: "",
			sortable: false,
			align: "right",
			headerAlign: "right",
			disableColumnMenu: true,
			flex: 1,
			renderCell: (
				params: GridRenderCellParams<string, IIncomingDataItemInverter>,
			) => {
				const apiRef = useGridApiContext();
				const { id } = params;
				if (editingRow !== id && apiRef.current.getRowMode(id) === "edit") {
					apiRef.current.setRowMode(editingRow as string, "view");
				}

				const handleEdit = () => {
					setEditingRow(id as string);
					apiRef.current.setRowMode(id, "edit");
				};

				const handleCancel = () => {
					setEditingRow(null);
					apiRef.current.setRowMode(id, "view");
				};
				return (
					<>
						{apiRef.current.getRowMode(id) === "view" ? (
							<Tooltip title="Editar cantidad">
								<IconButton
									size="small"
									onClick={handleEdit}
									color="primary"
									disabled={
										formEditableData?.sfv_information.installation_type ===
										"Medidor"
									}
								>
									<EditIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title="Cancelar edición">
								<IconButton size="small" onClick={handleCancel}>
									<CloseIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						)}
						<Tooltip title="Eliminar">
							<IconButton
								size="small"
								color="error"
								disabled={
									formEditableData?.sfv_information.installation_type ===
									"Medidor"
								}
								onClick={() => deleteInverter(params.row.inverter_serial ?? "")}
							>
								<DeleteIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</>
				);
			},
		},
	];

	return (
		<Box>
			<Grid item xs={12}>
				<Box
					sx={{
						width: "100%",
						"& .custom-header": {
							backgroundColor: "#EFE9FB",
							"&:first-of-type": {
								borderTopLeftRadius: "12px",
								borderBottomLeftRadius: "12px",
							},
							"&:last-child": {
								borderTopRightRadius: "12px",
								borderBottomRightRadius: "12px",
							},
						},
					}}
				>
					<StyledDataGrid
						sx={{
							"& .MuiDataGrid-virtualScrollerContent": {},
						}}
						columns={columnsInverters}
						pageSize={100}
						autoHeight
						hideFooter
						paginationMode="client"
						localeText={esES.components.MuiDataGrid.defaultProps.localeText}
						disableSelectionOnClick
						disableColumnFilter
						disableColumnSelector
						disableColumnMenu
						editMode="row"
						rows={
							invertersSolar?.map((item, index) => ({
								...item,
								id: index,
							})) ?? []
						}
					/>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box sx={{ display: "flex", justifyContent: "end", mt: "1rem" }}>
					<Button
						color="primary"
						variant="outlined"
						startIcon={<AddCircleOutlineIcon />}
						size="small"
						onClick={handleOpen}
						disabled={
							formEditableData?.sfv_information.installation_type === "Medidor"
						}
					>
						Agregar nuevo campo
					</Button>
					{open && <AddInverterSolar open={open} handleClose={handleClose} />}
				</Box>
			</Grid>
		</Box>
	);
};
