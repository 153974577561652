import {
	Button,
	FormControl,
	FormHelperText,
	Grid,
	MenuItem,
	SearchIcon,
	Select,
	type SelectChangeEvent,
	TextField,
	useSnackbar,
} from "@enerbit/base";
import { type FC, useEffect, useState } from "react";
import { AddressModal } from ".";
import { OrderTypes } from "../../enums/solar/ordersType";
import type { EEntity } from "../../interfaces";
import { useTableStore } from "../../store";

const emailRegex = /\S+@\S+\.\S+/;

interface Props {
	existsEntity?: EEntity;
}

export const SearchAddress: FC<Props> = ({ existsEntity }) => {
	const { enqueueSnackbar } = useSnackbar();

	const {
		loading,
		open,
		openModal,
		search,
		diagnosticType,
		setSearch,
		setDiagnosticType,
		errorMessage,
	} = useTableStore();

	const [emailError, setEmailError] = useState<string>("");

	const validateEmail = (email: string): boolean => {
		return emailRegex.test(email);
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const value = e.target.value;
		setSearch(value);
		if (validateEmail(value)) {
			setEmailError("");
		} else {
			setEmailError("Por favor, introduce un correo electrónico válido.");
		}
	};

	const handleFilterChange = (e: SelectChangeEvent<string | null>): void => {
		const value = e.target.value as OrderTypes;
		value && setDiagnosticType(value);
	};

	const searchAddress = () => {
		if (search && !emailError) {
			openModal();
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === "Enter") {
			searchAddress();
		}
	};

	useEffect(() => {
		if (errorMessage) {
			enqueueSnackbar(errorMessage, { variant: "error" });
		}
	}, [errorMessage, enqueueSnackbar]);

	return (
		<>
			<Grid item xs={12}>
				<FormControl fullWidth error={!!emailError}>
					<Grid container>
						<Grid item xs={2}>
							<Select
								sx={{
									"& fieldset": {
										borderRadius: "14px 0px 0px 14px",
									},
								}}
								fullWidth
								value={diagnosticType}
								onChange={handleFilterChange}
							>
								<MenuItem value={OrderTypes.conventional}>
									Convencional
								</MenuItem>
								<MenuItem value={OrderTypes.solar}>Solar</MenuItem>
							</Select>
						</Grid>
						<Grid item xs={8}>
							<TextField
								fullWidth
								onKeyDown={handleKeyDown}
								onChange={handleInputChange}
								disabled={loading}
								value={search}
								type="email"
								id="email"
								error={!!emailError}
								variant="outlined"
								placeholder="Buscar por correo electrónico"
								sx={{
									"& .MuiOutlinedInput-root": {
										"& fieldset": {
											borderRadius: "0px 14px 14px 0px",
										},
									},
								}}
							/>
						</Grid>
						<Grid item display="grid" xs={2}>
							<Button
								id="search"
								color="secondary"
								variant="contained"
								disabled={!search}
								onClick={searchAddress}
								sx={{
									ml: "10px",
								}}
								startIcon={<SearchIcon fontSize="small" />}
							>
								Buscar
							</Button>
						</Grid>
					</Grid>
					<FormHelperText>{emailError}</FormHelperText>
				</FormControl>
			</Grid>
			{open && <AddressModal existsEntity={existsEntity} />}
		</>
	);
};
