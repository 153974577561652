import { CheckCircleIcon, ErrorOutlineIcon, WarningIcon } from "@enerbit/base";
import type { IDynamicForm } from "../../interfaces";

export const formDiagnosticSolar: IDynamicForm[] = [
	{
		name: "measurement_type",
		label: "Tipo de medida",
		type: "select",
		sx: { xs: 12, md: 6, lg: 4 },
		items: [
			{
				text: "Directa",
				value: "directa",
			},
			{
				text: "Semidirecta",
				value: "semidirecta",
			},
			{
				text: "Indirecta",
				value: "indirecta",
			},
		],
	},
	{
		name: "opening",
		label: "Apertura",
		type: "text",
		sx: { xs: 12, md: 6, lg: 4 },
	},
	{
		name: "main_meter",
		label: "Medidor principal",
		type: "text",
		sx: { xs: 12, md: 6, lg: 4 },
	},
	{
		name: "comunication",
		label: "Comunicación",
		type: "text",
		sx: { xs: 12, md: 6, lg: 4 },
	},
	{
		name: "current_transformer",
		label: "Transformadores de corriente",
		type: "text",
		sx: { xs: 12, md: 6, lg: 4 },
	},
	{
		name: "voltage_transformer",
		label: "Transformadores de tensión",
		type: "text",
		sx: { xs: 12, md: 6, lg: 4 },
	},
	{
		name: "box",
		label: "Paneles o cajas",
		type: "text",
		sx: { xs: 12, md: 6, lg: 4 },
	},
	{
		name: "test_block",
		label: "Bloque de pruebas",
		type: "text",
		sx: { xs: 12, md: 6, lg: 4 },
	},

	{
		name: "control_wire",
		label: "Cable de control",
		type: "text",
		sx: { xs: 12, md: 6, lg: 4 },
	},

	{ name: "other", label: "Otros", type: "text", sx: { xs: 12, md: 6, lg: 4 } },
	{
		name: "status",
		label: "Indicar situación",
		type: "select",
		sx: { xs: 12, md: 12, lg: 12 },
		items: [
			{ text: "Viable", value: "ok", icon: CheckCircleIcon, color: "success" },
			{
				text: "Pendiente de adecuaciones",
				value: "pending",
				icon: WarningIcon,
				color: "secondary",
			},
			{
				text: "No viable",
				value: "no",
				icon: ErrorOutlineIcon,
				color: "error",
			},
		],
	},
	{
		name: "observation_work_order",
		label: "Observación para orden de servicio",
		type: "text-area",
		sx: { xs: 12 },
	},
];
