export const installationType = [
	{
		value: "Medidor",
		text: "Medidor",
	},
	{
		value: "Smartbit",
		text: "Smartbit",
	},
	{
		value: "App",
		text: "App",
	},
	{
		value: "Otro",
		text: "Otro",
	},
];

export const inverterType = [
	{
		value: "Centralizado",
		text: "Centralizado",
	},
	{
		value: "Microinversores",
		text: "Microinversores",
	},
];
