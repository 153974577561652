import { capitalize } from "@enerbit/base";
import type {
	IMeasurementSystem,
	IMeasurementSystemItem,
} from "../../interfaces";

export const generateMainMeterMS = (
	measurement_system?: IMeasurementSystem,
): IMeasurementSystemItem[] => {
	if (!measurement_system) return [];

	return [
		{
			field: "Tipo de medición",
			currentValue: capitalize(
				measurement_system?.main_meter_measurement_type
					.current_value_situation ?? "",
			),
			realValue: capitalize(
				measurement_system?.main_meter_measurement_type.ideal_value_situation ??
					"",
			),
			verification:
				!!measurement_system?.main_meter_measurement_type.validation,
		},
		{
			field: "Número de elementos del medidor",
			currentValue:
				measurement_system?.main_meter_number_meter_elements
					.current_value_situation ?? "",
			realValue:
				measurement_system?.main_meter_number_meter_elements
					.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.main_meter_number_meter_elements.validation,
		},
		{
			field: "Número de elementos de la conexión",
			currentValue:
				measurement_system?.main_meter_number_connection_elements
					.current_value_situation ?? "",
			realValue:
				measurement_system?.main_meter_number_connection_elements
					.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.main_meter_number_connection_elements.validation,
		},
		{
			field: "Índice de clase activa (%)",
			currentValue:
				measurement_system?.main_meter_active_class_index_percentage
					.current_value_situation ?? "",
			realValue:
				measurement_system?.main_meter_active_class_index_percentage
					.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.main_meter_active_class_index_percentage
					.validation,
		},
		{
			field: "Índice de clase reactiva (%)",
			currentValue:
				measurement_system?.main_meter_reactive_class_index_percentage
					.current_value_situation ?? "",
			realValue:
				measurement_system?.main_meter_reactive_class_index_percentage
					.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.main_meter_reactive_class_index_percentage
					.validation,
		},
		{
			field: "Marca del medidor",
			currentValue: capitalize(
				measurement_system?.main_meter_inspectioned_meter_brand
					.current_value_situation ?? "",
			),
			realValue: capitalize(
				measurement_system?.main_meter_inspectioned_meter_brand
					.ideal_value_situation ?? "",
			),
			verification:
				!!measurement_system?.main_meter_inspectioned_meter_brand.validation,
		},
		{
			field: "Modelo (referencia)",
			currentValue:
				measurement_system?.main_meter_inspectioned_meter_model
					.current_value_situation ?? "",
			realValue:
				measurement_system?.main_meter_inspectioned_meter_model
					.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.main_meter_inspectioned_meter_model.validation,
		},
		...(measurement_system.main_meter_measurement_type
			.current_value_situation === "semidirecta"
			? [
					{
						field: "Carga circuito de corriente (VA)",
						currentValue:
							measurement_system?.main_meter_inspectioned_circuit_load_current
								.current_value_situation ?? "",
						realValue:
							measurement_system?.main_meter_inspectioned_circuit_load_current
								.ideal_value_situation ?? "",
						verification:
							!!measurement_system?.main_meter_inspectioned_circuit_load_current
								.validation,
					},
				]
			: []),
		...(measurement_system.main_meter_measurement_type
			.current_value_situation === "indirecta"
			? [
					{
						field: "Carga circuito de tensión (VA)",
						currentValue:
							measurement_system?.main_meter_inspectioned_circuit_load_tension
								.current_value_situation ?? "",
						realValue:
							measurement_system?.main_meter_inspectioned_circuit_load_tension
								.ideal_value_situation ?? "",
						verification:
							!!measurement_system?.main_meter_inspectioned_circuit_load_tension
								.validation,
					},
				]
			: []),
	];
};
