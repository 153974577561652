import {
	Box,
	Button,
	CircularProgress,
	DialogContent,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	type SelectChangeEvent,
} from "@enerbit/base";
import { type FC, useEffect, useState } from "react";
import { StyledDialog, StyledDialogTitle } from "../../common";
import { convertMoneyToString } from "../../helpers";
import { transformMaterial } from "../../helpers/material-labor";
import type { keyMaterialsSolar } from "../../interfaces/IEmissionStoreSolar";
import { useEmissionStore } from "../../store";
import { useEmissionStoreSolar } from "../../store/emission-solar";

interface Props {
	open: boolean;
	isSolar?: boolean;
	keyDataSolar?: keyMaterialsSolar;
	handleClose: () => void;
}

export const AddMaterialModal: FC<Props> = ({
	open,
	handleClose,
	isSolar,
	keyDataSolar,
}) => {
	const {
		materials,
		listCategories,
		listMaterials,
		isLoadingMaterials,
		listQuantities,
		getMaterials,
		addMaterial,
		updateQuantityMaterial,
	} = useEmissionStore();

	const { addMaterialSolar, updateQuantityMaterialSolar } =
		useEmissionStoreSolar();

	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedMaterial, setSelectedMaterial] = useState("");
	const [selectedQuantity, setSelectedQuantity] = useState("");

	const handleCategoryChange = (event: SelectChangeEvent) => {
		setSelectedCategory(event.target.value);
	};

	const handleMaterialChange = (event: SelectChangeEvent) => {
		setSelectedMaterial(event.target.value);
	};

	const handleQuantityChange = (event: SelectChangeEvent) => {
		setSelectedQuantity(event.target.value);
	};

	const handleAddMaterial = () => {
		const materialInfo = listMaterials.find(
			(material) => material["Texto breve material"] === selectedMaterial,
		);
		if (materialInfo) {
			const materialName = materialInfo["Texto breve material"];
			const existingMaterial = materials.find(
				(mat) => mat.name.toLowerCase() === materialName.toLowerCase(),
			);

			if (existingMaterial) {
				const updatedQuantity =
					Number(existingMaterial.quantity) + Number(selectedQuantity);

				isSolar && keyDataSolar
					? updateQuantityMaterialSolar(
							existingMaterial.id,
							updatedQuantity,
							keyDataSolar,
						)
					: updateQuantityMaterial(existingMaterial.id, updatedQuantity);
			} else {
				const newMaterial = transformMaterial({
					name: materialName,
					charge: materialInfo["Valor unitario con IVA"]
						? convertMoneyToString(materialInfo["Valor unitario con IVA"])
						: "0",
					quantity: Number(selectedQuantity ?? 1),
					unit: materialInfo["Unidad de medida"],
				});
				isSolar && keyDataSolar
					? addMaterialSolar(newMaterial, keyDataSolar)
					: addMaterial(newMaterial);
			}
			handleClose();
		}
	};

	useEffect(() => {
		if (selectedCategory) {
			getMaterials(selectedCategory);
		}
	}, [selectedCategory, getMaterials]);

	return (
		<StyledDialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			fullWidth
		>
			<StyledDialogTitle id="customized-dialog-title" onClose={handleClose}>
				Ingresar un nuevo registro
			</StyledDialogTitle>
			<DialogContent>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<InputLabel>Categoría</InputLabel>
						<FormControl fullWidth>
							<Select
								value={selectedCategory}
								onChange={handleCategoryChange}
								disabled={isLoadingMaterials}
								displayEmpty
								sx={{
									"& fieldset": { borderRadius: "14px" },
								}}
							>
								{listCategories.map((item, index) => (
									<MenuItem value={item} key={index.toString()}>
										{item}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					{selectedCategory && (
						<Grid item xs={12}>
							<InputLabel>Material</InputLabel>
							<FormControl fullWidth>
								<Select
									value={selectedMaterial}
									onChange={handleMaterialChange}
									displayEmpty
									disabled={isLoadingMaterials}
									sx={{
										"& fieldset": { borderRadius: "14px" },
										pr: isLoadingMaterials ? 3 : 0,
									}}
									endAdornment={
										isLoadingMaterials ? (
											<InputAdornment position="start">
												<CircularProgress size={20} thickness={6} />
											</InputAdornment>
										) : null
									}
								>
									{listMaterials.map((item, index) => (
										<MenuItem
											value={item["Texto breve material"]}
											key={index.toString()}
										>
											{item["Texto breve material"]}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					)}
					{selectedMaterial && (
						<Grid item xs={12}>
							<InputLabel>Cantidad</InputLabel>
							<FormControl fullWidth>
								<Select
									value={selectedQuantity}
									onChange={handleQuantityChange}
									displayEmpty
									sx={{
										"& fieldset": { borderRadius: "14px" },
									}}
								>
									{listQuantities.map((item, index) => (
										<MenuItem value={item} key={index.toString()}>
											{item}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					)}
					<Grid item xs={12}>
						<Box
							sx={{ width: "100%", display: "flex", justifyContent: "center" }}
						>
							<Button
								color="primary"
								variant="contained"
								disabled={!selectedQuantity}
								onClick={handleAddMaterial}
							>
								Agregar
							</Button>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
		</StyledDialog>
	);
};
