export const meterLocation = [
	{
		text: "Caja individual externa",
		value: "caja individual externa",
	},
	{
		text: "Caja individual interna",
		value: "caja individual interna",
	},
	{
		text: "Celda individual interna",
		value: "celda individual interna",
	},
	{
		text: "Celda compartida externa",
		value: "celda compartida externa",
	},
	{
		text: "Gabinete compartido interno",
		value: "gabinete compartido interno",
	},
	{
		text: "Gabinete compartido externo",
		value: "gabinete compartido externo",
	},
];

export const optionalLocation = [
	{
		text: "Caja individual externa",
		value: "caja individual externa",
	},
	{
		text: "Caja individual externa",
		value: "caja individual externa",
	},
	{
		text: "Celda compartida externa",
		value: "celda compartida externa",
	},
	{
		text: "Celda individual interna",
		value: "celda individual interna",
	},
	{
		text: "Gabinete compartido interno",
		value: "gabinete compartido interno",
	},
	{
		text: "Gabinete compartido externo",
		value: "gabinete compartido externo",
	},
	{
		text: "Subestación",
		value: "subestación",
	},
];

export const meterLocationSeals = [
	{
		text: "Tapa principal",
		value: "tapa principal",
	},
	{
		text: "Bornera de conexión",
		value: "bornera de conexión",
	},
	{
		text: "Demanda máxima",
		value: "demanda máxima",
	},
];

export const measurementType = [
	{
		text: "Directa",
		value: "directa",
	},
	{
		text: "Semidirecta",
		value: "semidirecta",
	},
	{
		text: "Indirecta",
		value: "indirecta",
	},
];

export const meterSize = [
	{
		text: "Pequeño",
		value: "pequeño",
	},
	{
		text: "Mediano",
		value: "mediano",
	},
	{
		text: "Grande",
		value: "grande",
	},
];

export const numberOfPhases = [
	{
		text: "Bifásico",
		value: "bifásico",
	},
	{
		text: "Trifásico",
		value: "trifásico",
	},
	{
		text: "Monofásico",
		value: "monofásico",
	},
];

export const numberMetersElements = [
	{ text: "1E", value: "1E" },
	{ text: "2E", value: "2E" },
	{ text: "3E", value: "3E" },
];

export const numberConnectionsElements = [
	{ text: "1E", value: "1E" },
	{ text: "1 1/2E", value: "1 1/2E" },
	{ text: "2E", value: "2E" },
	{ text: "2 ARON", value: "2 ARON" },
	{ text: "3E", value: "3E" },
];

export const activeClassIndexPercentage = ["1", "2", "3", "0,2S", "0,5S"];

export const meterType = [
	{ text: "Instalado", value: "instalado" },
	{ text: "Encontrado", value: "encontrado" },
];

export const meterOwnership = [
	{ text: "Usuario", value: "usuario" },
	{ text: "RF", value: "rf" },
	{ text: "OR", value: "or" },
	{ text: "Otro RF", value: "otro rf" },
];

export const connectionMap = [
	{ text: "Simétrica", value: "simétrica" },
	{ text: "Asimétrica", value: "asimétrica" },
];

export const usedElement = [
	"1",
	"2",
	"3",
	"1 y 2",
	"2 y 3",
	"1 y 3",
	"1,2 y 3",
];

export const outlets = [
	{ text: "Toma fase 1", value: "toma fase 1" },
	{ text: "Toma fase 2", value: "toma fase 2" },
	{ text: "Toma fase 3", value: "toma fase 3" },
	{ text: "Sin toma", value: "sin toma" },
];

export const wires = ["1", "2", "3", "4"];

export const conectionPhases = ["1", "2", "3"];
