import type {
	ICurrentTransformer,
	IInstallationCost,
	IVoltageTransformer,
} from "../interfaces";

type ServiceType = "home" | "company";

export const checkTransformerPhases = (
	current_transformer?: ICurrentTransformer | null,
	voltage_transformer?: IVoltageTransformer | null,
): number => {
	const countPhases = (
		transformer: ICurrentTransformer | IVoltageTransformer,
	) => {
		const phases = ["r_phase", "s_phase", "t_phase"] as const;

		return phases.reduce((count, phase) => {
			return transformer && transformer[phase] !== null ? count + 1 : count;
		}, 0);
	};

	const phasesCT = current_transformer ? countPhases(current_transformer) : 0;
	const phasesVT = voltage_transformer
		? countPhases(voltage_transformer)
		: phasesCT;

	return Math.min(phasesCT, phasesVT);
};

export const selectByMeasurement = (
	serviceType: string,
	measurementType: string,
	installationCosts: IInstallationCost[],
) => {
	const categoryMap: {
		[key in ServiceType]: string;
	} = {
		home: "Directa residencial",
		company: "Directa",
	};

	// Asegurar que serviceType sea una clave válida en categoryMap antes de intentar acceder
	const category =
		(serviceType as ServiceType) in categoryMap
			? categoryMap[serviceType as ServiceType]
			: null;

	const measurement = installationCosts.find(({ category: cat }) =>
		category ? cat === category : cat.toLowerCase().includes(measurementType),
	);

	return measurement?.installations;
};
