import type {
	ICurrentTransformer,
	IElectricalBox,
	IElectricalConductors,
	IGeneralData,
	IInputDataFormData,
	IMainMeter,
	IOptionalMeter,
	IPictureArray,
	IPicturesInspection,
	ITerminal,
	ITransformerPictures,
	IVoltageTransformer,
	PhotographicAnnexElement,
} from "../../interfaces";

export const getAllPicturesByInspectionForm = (
	formData: IInputDataFormData,
): IPicturesInspection => ({
	general_data: getGeneralDataPhotos(formData.general_data),
	main_meter: getMetersPhotos(formData.main_meter),
	optional_meter: getMetersPhotos(formData.optional_meter),
	current_transformer: getTransformersPictures(formData.current_transformer),
	phases_current_transformer: getTCPictures(formData.current_transformer),
	voltage_transformer: getTransformersPictures(formData.voltage_transformer),
	phases_voltage_transformer: getTTPictures(formData.voltage_transformer),
	electrical_boxes: getElectricalBoxesPhotos(formData.electrical_boxes),
	electrical_conductors: getConductorsPhotos(formData.electrical_conductors),
	terminals: getTerminalsPhotos(formData.terminals),
	photographic_annexes: getAnnexPhotos(formData.photographic_annexes),
});

const getGeneralDataPhotos = (
	general_data?: IGeneralData | null,
): IPictureArray[] => {
	if (!general_data) {
		return [];
	}
	const photoMapping = [
		{ title: "Fachada panorámica", key: "panoramic_facade_photo" },
		{ title: "Capacidad contratada", key: "power_capacity_contracted" },
		{
			title: "Transformador de potencia",
			key: "power_transformer_feature_plate_picture",
		},
		{
			title: "Panorámica del sistema de medida",
			key: "measurement_system_picture",
		},
	] as const;

	const arrayPictures = photoMapping
		.filter(({ key }) => general_data[key])
		.map(({ title, key }) => ({
			image_title: title,
			image_url: general_data[key]?.picture_url,
		}));

	return arrayPictures;
};

const getMetersPhotos = (
	main_meter?: IMainMeter | IOptionalMeter | null,
): IPictureArray[] => {
	if (!main_meter) {
		return [];
	}
	const arrayPictures = [
		{
			image_title: "Medidor",
			image_url: main_meter?.meter_serial.picture_url,
		},
		...(main_meter?.calibration_date
			? [
					{
						image_title: "Fecha de calibración",
						image_url: main_meter.calibration_date?.picture_url,
					},
				]
			: []),
		...(main_meter?.seals.map((seal, index) => ({
			image_title: `Sello medidor ${index + 1}`,
			image_url: seal.serial_number.picture_url,
		})) ?? []),
	];

	return arrayPictures;
};

const getElectricalBoxesPhotos = (
	electrical_boxes?: IElectricalBox[] | null,
): IPictureArray[] => {
	if (!electrical_boxes || electrical_boxes.length === 0) {
		return [];
	}

	const arrayPictures = electrical_boxes.flatMap((box) =>
		[
			{
				image_title: "Fabricante",
				image_url: box.manufacturer?.picture_url,
			},
			{
				image_title: "Año de fabricación",
				image_url: box.manufacture_year?.picture_url,
			},
		].filter((item) => item.image_url),
	);

	return arrayPictures;
};

const getTerminalsPhotos = (
	terminals?: ITerminal[] | null,
): IPictureArray[] => {
	if (!terminals || terminals.length === 0) {
		return [];
	}

	const arrayPictures = terminals
		.flatMap((terminal) => {
			const sealPictures = terminal.seals
				? terminal.seals.flatMap((seal, index) => ({
						image_title: `Sello Bornera ${index + 1}`,
						image_url: seal.serial_number?.picture_url,
					}))
				: [];

			const manufacturerPicture =
				typeof terminal.manufacturer !== "string" &&
				terminal.manufacturer.picture_url
					? [
							{
								image_title: "Bornera",
								image_url: terminal.manufacturer.picture_url,
							},
						]
					: [];

			return [...manufacturerPicture, ...sealPictures];
		})
		.filter((item) => item.image_url);

	return arrayPictures;
};

const getAnnexPhotos = (
	photographic_annexes?: PhotographicAnnexElement[] | null,
): IPictureArray[] => {
	if (!photographic_annexes || photographic_annexes.length === 0) {
		return [];
	}

	const arrayPictures = photographic_annexes.flatMap((item, index) =>
		item.photographic_annex
			? [
					{
						image_title: `Foto libre ${index + 1}`,
						image_url: item.photographic_annex.picture_url,
					},
				]
			: [],
	);

	return arrayPictures;
};

const getConductorsPhotos = (
	electrical_conductors?: IElectricalConductors | null,
): IPictureArray[] => {
	if (!electrical_conductors) {
		return [];
	}

	const arrayPictures: IPictureArray[] = [
		...(electrical_conductors.current_signal?.gauge?.picture_url
			? [
					{
						image_title: "Calibre (corriente)",
						image_url: electrical_conductors.current_signal.gauge?.picture_url,
					},
				]
			: []),
		...(electrical_conductors.voltage_signal?.gauge?.picture_url
			? [
					{
						image_title: "Calibre (tensión)",
						image_url: electrical_conductors.voltage_signal.gauge?.picture_url,
					},
				]
			: []),
	];

	return arrayPictures;
};

export const getTCPictures = (
	current_transformer?: ICurrentTransformer | null,
): ITransformerPictures | null => {
	if (!current_transformer) {
		return null;
	}

	const phases = ["r_phase", "s_phase", "t_phase"] as const; // Para asegurar tipos específicos durante la iteración

	const arrayPictures: ITransformerPictures = phases.reduce((acc, phase) => {
		const phaseData = current_transformer[phase];
		if (phaseData) {
			acc[phase] = [
				{
					image_title: "Transformador",
					image_url: phaseData.serial_number?.picture_url,
				},
				{
					image_title: "Estampilla de calibración 1ra relación",
					image_url:
						phaseData.calibration_certificate.first_relation_calibration_stamp
							?.picture_url,
				},
				{
					image_title: "Estampilla de calibración 2da relación",
					image_url:
						phaseData.calibration_certificate.second_relation_calibration_stamp
							?.picture_url,
				},
			];
		} else {
			acc[phase] = [];
		}
		return acc;
	}, {} as ITransformerPictures);

	return arrayPictures;
};

export const getTTPictures = (
	voltage_transformer?: IVoltageTransformer | null,
): ITransformerPictures | null => {
	if (!voltage_transformer) {
		return null;
	}

	const phases = ["r_phase", "s_phase", "t_phase"] as const;

	const arrayPictures: ITransformerPictures = phases.reduce((acc, phase) => {
		const phaseData = voltage_transformer[phase];
		if (phaseData) {
			acc[phase] = [
				{
					image_title: "Transformador",
					image_url: phaseData.serial_number?.picture_url,
				},
				{
					image_title: "Estampilla de calibración",
					image_url:
						phaseData.calibration_certificate.calibration_stamp?.picture_url,
				},
			];
		} else {
			acc[phase] = [];
		}
		return acc;
	}, {} as ITransformerPictures);

	return arrayPictures;
};

export const getTransformersPictures = (
	transformer?: IVoltageTransformer | ICurrentTransformer | null,
): IPictureArray[] => {
	if (!transformer) {
		return [];
	}

	return [
		{
			image_title: "Ubicación",
			image_url: transformer.location?.picture_url,
		},
	];
};
