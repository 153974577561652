import { capitalize } from "@enerbit/base";
import type {
	ICurrentTransformer,
	IMeasurementSystem,
	IMeasurementSystemItem,
} from "../../interfaces";

export const generateCurrentTransformerMS = (
	measurement_system?: IMeasurementSystem,
	current_transformer?: ICurrentTransformer | null,
): IMeasurementSystemItem[] => {
	if (!measurement_system || !current_transformer) return [];

	const items = [
		{
			field: "Clase de exactitud (%)",
			currentValue:
				measurement_system?.current_transformer_accuracy_class
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.current_transformer_accuracy_class
					?.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.current_transformer_accuracy_class?.validation,
		},
		{
			field: "Ubicación de los transformadores de corriente",
			currentValue: capitalize(
				measurement_system?.current_transformer_location
					?.current_value_situation ?? "",
			),
			realValue: capitalize(
				measurement_system?.current_transformer_location
					?.ideal_value_situation ?? "",
			),
			verification:
				!!measurement_system?.current_transformer_location?.validation,
		},
		{
			field: "Tipo exterior o interior",
			currentValue: capitalize(
				measurement_system?.current_transformer_is_exterior
					?.current_value_situation ?? "",
			),
			realValue: capitalize(
				measurement_system?.current_transformer_is_exterior
					?.ideal_value_situation ?? "",
			),
			verification:
				!!measurement_system?.current_transformer_is_exterior?.validation,
		},
		{
			field: "Corriente Secundaria nominal (A)",
			currentValue:
				measurement_system?.current_transformer_rated_secondary_current
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.current_transformer_rated_secondary_current
					?.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.current_transformer_rated_secondary_current
					?.validation,
		},
		{
			field: "Carga transformadores de corriente (VA)",
			currentValue:
				measurement_system?.current_transformer_load?.current_value_situation ??
				"",
			realValue:
				measurement_system?.current_transformer_load?.ideal_value_situation ??
				"",
			verification: !!measurement_system?.current_transformer_load?.validation,
		},
		{
			field: "Burden transformadores de corriente (VA)",
			currentValue:
				measurement_system?.current_transformer_burden
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.current_transformer_burden?.ideal_value_situation ??
				"",
			verification:
				!!measurement_system?.current_transformer_burden?.validation,
		},
		{
			field: "Cargas para compensación de burden (VA)",
			currentValue:
				measurement_system?.current_transformer_charges_burden_compensation
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.current_transformer_charges_burden_compensation
					?.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.current_transformer_charges_burden_compensation
					?.validation,
		},
		{
			field: "Carga calculada transformadores de corriente [%]",
			currentValue:
				measurement_system?.current_transformer_calculed_load
					?.current_value_situation ?? "",
			realValue:
				measurement_system?.current_transformer_calculed_load
					?.ideal_value_situation ?? "",
			verification:
				!!measurement_system?.current_transformer_calculed_load?.validation,
		},
	];

	const filteredItems = items.filter(
		(item) => item.currentValue !== "" || item.realValue !== "",
	);

	return filteredItems;
};
