import {
	AddCircleOutlineIcon,
	Box,
	Button,
	DeleteIcon,
	FormHelperText,
	Grid,
	IconButton,
	KeyboardArrowRightIcon,
	Stack,
	Typography,
	enerbitColors,
	theme,
} from "@enerbit/base";
import { type FC, type SyntheticEvent, useState } from "react";
import { get, useFieldArray, useFormContext } from "react-hook-form";
import {
	StyledAccordion,
	StyledAccordionDetails,
	StyledAccordionSummary,
} from "../../../common/StyledAccordion";
import type { IDynamicForm } from "../../../interfaces";
import { EmptyData } from "../../empty-data";
import { FormItem } from "../FormItem";

export const ArrayForm: FC<IDynamicForm> = ({
	name,
	label,
	children,
	json,
	props,
}) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: name ?? "",
	});

	const [expanded, setExpanded] = useState<number | null>(0);

	const handleChange =
		(index: number) => (_: SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? index : null);
		};

	const handleAdd = () => {
		append(json);
	};

	return (
		<Grid container item xs={12} spacing={3}>
			{fields.map((_, index: number) => (
				<Grid key={index.toString()} item xs={12}>
					<StyledAccordion
						expanded={expanded === index}
						onChange={handleChange(index)}
						sx={{
							border: `1px solid ${enerbitColors.primary.main}`,
						}}
						id={name}
					>
						<StyledAccordionSummary
							sx={{
								background: "#EFE9FB",
								"& .MuiAccordionSummary-expandIconWrapper": {
									color: enerbitColors.primary.main,
								},
								"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
									transform: "rotate(90deg)",
								},
							}}
							expandIcon={
								<KeyboardArrowRightIcon sx={{ fontSize: "1.5rem" }} />
							}
						>
							<Stack
								spacing={3}
								direction={"row"}
								sx={{ display: "flex", alignItems: "center", width: "100%" }}
							>
								<Box sx={{ flex: 1 }}>
									<Typography
										sx={{
											fontWeight: 700,
											color: enerbitColors.primary.main,
										}}
									>
										{label} # {index + 1}
									</Typography>
								</Box>
								{!props?.disabled && (
									<IconButton
										id={`delete-${name}`}
										color="error"
										size="small"
										sx={{ marginX: 0 }}
										onClick={() => remove(index)}
									>
										<DeleteIcon fontSize="small" />
									</IconButton>
								)}
							</Stack>
						</StyledAccordionSummary>
						<StyledAccordionDetails
							sx={{
								padding: theme.spacing(3),
								borderTop: "1px solid rgba(0, 0, 0, .125)",
							}}
						>
							<Grid container spacing={3}>
								{children?.map((item, i) => {
									const currentField = {
										...item,
										name: item.name
											? `${name}.${index}.${item.name}`
											: `${name}.${index}`,
										props: { disabled: props?.disabled, ...item.props },
									};
									return <FormItem {...currentField} key={i.toString()} />;
								})}
							</Grid>
						</StyledAccordionDetails>
					</StyledAccordion>
				</Grid>
			))}
			{fields.length === 0 && (
				<Grid item xs={12}>
					<EmptyData message="Aún no se ha agregado nada." />
					{!!get(errors, name) && (
						<FormHelperText error>{get(errors, name).message}</FormHelperText>
					)}
				</Grid>
			)}
			{!props?.disabled && (
				<Grid item display={"grid"} xs={12}>
					<Button
						id={`add-${name}`}
						onClick={handleAdd}
						variant="outlined"
						color="primary"
						startIcon={<AddCircleOutlineIcon />}
					>
						Agregar
					</Button>
				</Grid>
			)}
		</Grid>
	);
};
