import { Box, Button, DialogContent, Grid } from "@enerbit/base";
import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { StyledDialog, StyledDialogTitle } from "../../../common";
import { formInvertersSolar } from "../../../form-builders/solar/form-inverters-solar";
import type { IIncomingDataItemInverter } from "../../../interfaces";
import { useEmissionStoreSolar } from "../../../store/emission-solar";
import { FormItem } from "../../dynamic-form";

interface Props {
	open: boolean;
	handleClose: () => void;
}

export const AddInverterSolar: FC<Props> = ({ open, handleClose }) => {
	const { addInverters } = useEmissionStoreSolar();

	const handleAddMaterial = async () => {
		if (await trigger()) {
			const values = getValues();
			addInverters({
				inverter_brand: values.inverter_brand,
				inverter_model: values.inverter_model,
				inverter_serial: values.inverter_serial,
			});
			handleClose();
		}
	};

	const validationSolarInverters = z.object({
		inverter_brand: z.string().min(1, "Este campo es obligatorio."),
		inverter_model: z.string().min(1, "Este campo es obligatorio."),
		inverter_serial: z.string().min(1, "Este campo es obligatorio."),
	});

	const methods = useForm<IIncomingDataItemInverter>({
		resolver: zodResolver(validationSolarInverters),
		defaultValues: {
			inverter_brand: "",
			inverter_model: "",
			inverter_serial: "",
		},
		mode: "onChange",
	});

	const {
		formState: { errors },
		watch,
		getValues,
		trigger,
	} = methods;

	return (
		<StyledDialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			fullWidth
		>
			<StyledDialogTitle id="customized-dialog-title" onClose={handleClose}>
				Ingresar un nuevo registro
			</StyledDialogTitle>
			<DialogContent>
				<FormProvider {...methods}>
					<form onSubmit={(e) => e.preventDefault()}>
						<Grid container spacing={3}>
							{formInvertersSolar.map((item, index) => {
								const newItem = {
									...item,
									name: `${item.name}`,
								};
								return <FormItem {...newItem} key={index.toString()} />;
							})}
							<Grid item xs={12}>
								<Box
									sx={{
										width: "100%",
										display: "flex",
										justifyContent: "center",
									}}
								>
									<Button
										color="primary"
										variant="contained"
										onClick={handleAddMaterial}
									>
										Agregar
									</Button>
								</Box>
							</Grid>
						</Grid>
					</form>
				</FormProvider>
			</DialogContent>
		</StyledDialog>
	);
};
