import {
	Box,
	Button,
	CancelRoundedIcon,
	Grid,
	Typography,
} from "@enerbit/base";
import { useState } from "react";
import { useEmissionStore } from "../../store";
import { InstallationCostsModal } from "./InstallationCostsModal";

export const ViabilityError = () => {
	const { installation, outputData } = useEmissionStore();

	const [open, setOpen] = useState(false);

	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<Grid item xs={12}>
			<Box
				sx={{
					borderRadius: "12px",
					border: "1px solid #F04438",
					backgroundColor: "#FEECEB",
					display: "flex",
					flexDirection: { xs: "column", md: "row" }, // Columna en móviles, fila en pantallas más grandes
					justifyContent: "space-between",
					alignItems: { md: "center" }, // Alinea los ítems al centro en sm hacia arriba, no necesario en xs debido al flexDirection
					width: "100%",
					gap: 4,
					p: 2,
				}}
			>
				<Box sx={{ display: "flex", gap: 4, alignItems: "center", flex: 2 }}>
					<CancelRoundedIcon fontSize="large" color="error" />

					<Typography color={"#000"} sx={{ flex: 1 }}>
						{outputData?.field?.input_data.form_data.main_meter?.measurement_type.toLowerCase() !==
						"directa"
							? "La información disponible es insuficiente para determinar automáticamente un diagnóstico. Para brindar una recomendación precisa, es crucial conocer el número de fases en el sistema de energía. En esta ocasión, será necesario que realices una recomendación manual."
							: "No se ha encontrado el tipo de servicio necesario para recomendar un diagnóstico. Para asegurar una recomendación precisa, requerimos información sobre el tipo de servicio. Puede proporcionar una recomendación manual o solicitar actualizar la información del Lead."}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flex: 1,
						justifyContent: { xs: "center", md: "flex-end" },
						mt: { xs: 2, md: 0 },
					}}
				>
					<Button
						color="primary"
						variant="contained"
						size="small"
						onClick={handleOpen}
					>
						Costos de instalación
					</Button>
				</Box>

				<InstallationCostsModal open={open} handleClose={handleClose} />
			</Box>
		</Grid>
	);
};
