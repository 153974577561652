import { v4 as uuidv4 } from "uuid";
import { getAllMaterials } from "..";
import type {
	IActivity,
	IActivityExtended,
	IMaterial,
	IMaterialExtended,
} from "../../interfaces";

export const transformMaterials = async (
	materials?: IMaterial[],
): Promise<IMaterialExtended[]> => {
	if (!materials) return [];

	const newMaterials = await getAllMaterials(materials);

	return newMaterials.map((material) => {
		const currentQuantity = material.quantity || 1;
		const subtotal = Number.parseFloat(material.charge) * currentQuantity;

		return {
			...material,
			id: uuidv4(),
			charge: material.charge,
			quantity: currentQuantity,
			subtotal,
		};
	});
};

export const restoreTransformMaterials = (
	materials: IMaterialExtended[],
): IMaterial[] => {
	return materials.map((material) => {
		const { name, charge, quantity, unit, category, material_code_sap } =
			material;

		return {
			name,
			charge,
			quantity,
			unit,
			category,
			material_code_sap,
		};
	});
};

export const transformActivities = (
	activities?: IActivity[],
): IActivityExtended[] => {
	if (!activities) return [];
	return activities.map((activity) => ({
		...activity,
		id: uuidv4(),
		charge: activity.charge.toString(),
		name: activity.name,
		quantity: 1,
		subtotal: activity.charge,
		unit: "UN",
	}));
};

export const transformMaterial = (material: IMaterial): IMaterialExtended => {
	return {
		...material,
		id: uuidv4(),
		quantity: material.quantity ?? 1,
		subtotal: Number.parseFloat(material.charge) * (material.quantity || 1),
	};
};

export const calculateTotal = (materials: IMaterialExtended[]): number => {
	return materials.reduce((acc, material) => {
		const quantity = material.quantity ?? 1;
		const charge = Number.parseFloat(material.charge);
		const subtotal = quantity * charge;
		return acc + subtotal;
	}, 0);
};
